<div class="assortmentDashbord">
	<div class="row">
		<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
			<div class="main-menu consumer-drive-header">
				<div>
					<div class="mr-4">
						<div class="reportHeader">
							<h4 class="m-0 reportName">{{ pageTitle }}</h4>
						</div>
						<br />
						<div class="mb-0 reportMenu">
							<div>
								<!-- Fact -->
								<span
									*ngIf="requestService.isInnerTable"
									class="report-dropdown dropdown dropdown-ovt facts-link"
									[ngClass]="{ 'field-disableOverlay': !requestService.isInnerTable }"
								>
									<a
										id="baseFactChooserListBtn"
										type="button"
										(click)="showFactMenu()"
										data-toggle="dropdown"
										aria-haspopup="true"
										aria-expanded="false"
										title="Click for all available facts"
										[ngClass]="showFactSelectorDropdown ? 'prompt-expanded' : ''"
									>
										<span class="text">{{ colHeaders.split(',').length }} Facts</span>
									</a>
									<ng-template #baseFactTooltipContent>
										<div style="padding: 9px 14px; color: #000000">
											{{ pageHoverTexts.basefactTooltip }}
										</div>
									</ng-template>
									<crf-ca-fact-selector
										[hidden]="!showFactSelectorDropdown"
										[factData]="factList"
										(selectedFacts)="onFactSelect($event)"
										[isHeaderMultiSelect]="true"
										[isOtherColumnPresent]="true"
										[nonModifiableColumns]="defaultFactColumns"
										(onDefaultChange)="onMoreOptionClicked($event)"
										(closeDropdown)="showFactSelectorDropdown = false"
										[configId]="qid"
									>
									</crf-ca-fact-selector>
								</span>
								<span *ngIf="requestService.isInnerTable"> for </span>
								<!-- Base Fact -->
								<span
									class="report-dropdown dropdown dropdown-ovt facts-link"
									[ngClass]="{ 'field-disableOverlay': requestService.isInnerTable }"
									*ngIf="!requestService.isInnerTable"
								>
									<a
										id="baseFactSelectBtn"
										type="button"
										(click)="showBaseFactMenu()"
										[niqTooltip]="baseFactTooltipContent"
										niqTooltipPosition="bottom"
										[niqTooltipColorTheme]="'dark'"
										data-toggle="dropdown"
										aria-haspopup="true"
										aria-expanded="false"
										[ngClass]="showBaseFactSelectDropdown ? 'prompt-expanded' : ''"
									>
										<span class="text">{{ selectedBaseFact }}</span>
									</a>
									<ng-template #baseFactTooltipContent>
										<div style="padding: 9px 14px; color: #000000">
											{{ pageHoverTexts.basefactTooltip }}
										</div>
									</ng-template>
									<app-dropdown-menu
										[hidden]="!showBaseFactSelectDropdown"
										[selectorType]="3"
										[items]="baseFactList"
										(selectedItem)="baseFactSelected($event)"
										[configId]="qid"
										(onDefaultChange)="onMoreOptionClicked($event)"
									></app-dropdown-menu>
								</span>
								<span *ngIf="!requestService.isInnerTable"> for </span>
								<span
									class="report-dropdown dropdown dropdown-ovt select-dropdown"
									[ngClass]="{ 'field-disableOverlay': requestService.isInnerTable }"
									promptClickOutside
									(clickOutside)="showProductView = false"
								>
									<a
										(click)="showProduct()"
										id="productSelect"
										readonly
										[niqTooltip]="productTooltipContent"
										niqTooltipPosition="bottom"
										[niqTooltipColorTheme]="'dark'"
										[ngClass]="showProductView ? 'prompt-expanded' : ''"
									>
										<span class="product-input-text"> {{ selectedProductToShow.value }} </span>
									</a>
									<crf-ca-product-selector-single
										[hidden]="!showProductView"
										(onClose)="showProduct()"
										[selectorType]="3"
										[configId]="qid"
										(updateReport)="updateProductSelection($event)"
										(onDefaultChange)="onMoreOptionClicked($event)"
									></crf-ca-product-selector-single>
									<ng-template #productTooltipContent>
										<div style="padding: 9px 14px; color: #000000">
											{{ pageHoverTexts.productTooltip }}
										</div>
									</ng-template>
								</span>
								<!-- Market -->
								in
								<span
									class="report-dropdown dropdown dropdown-ovt"
									[ngClass]="{ 'field-disableOverlay': requestService.isInnerTable }"
								>
									<a
										id="marketSelectorBtn"
										type="button"
										(click)="showMarketMenu()"
										[niqTooltip]="marketTooltipContent"
										niqTooltipPosition="bottom"
										[niqTooltipColorTheme]="'dark'"
										data-toggle="dropdown"
										aria-haspopup="true"
										aria-expanded="false"
										[ngClass]="showMulticheckDropdown ? 'prompt-expanded' : ''"
									>
										<span>{{ assortmentCtrl.selectedMarket[0] }}</span>
										<span
											*ngIf="assortmentCtrl.selectedMarket.length > 1"
											title="{{ assortmentCtrl.selectedMarket.join(',') }}"
											class="badge item-count pl-2"
											>+{{ assortmentCtrl.selectedMarket.length - 1 }}
										</span>
									</a>
									<ng-template #marketTooltipContent>
										<div style="padding: 9px 14px; color: #000000">
											{{ pageHoverTexts.marketTooltip }}
										</div>
									</ng-template>
									<app-multiselect-dropdown
										*ngIf="showMulticheckDropdown"
										[selectorType]="2"
										[configId]="qid"
										[marketData]="marketMenuItems"
										(onCancel)="showMarketMenu()"
										(onApply)="addselectedMarket($event)"
										(onDefaultChange)="onMoreOptionClicked($event)"
									></app-multiselect-dropdown>
								</span>
								<!-- Period -->
								during
								<span
									class="col-lg-2 col-md-2 col-sm-2 col-xs-12"
									class="report-dropdown dropdown dropdown-ovt"
									[ngClass]="{ 'field-disableOverlay': requestService.isInnerTable }"
								>
									<a
										id="periodSelectorBtn"
										type="button"
										(click)="showPeriodMenu()"
										[niqTooltip]="periodTooltipContent"
										niqTooltipPosition="bottom"
										[niqTooltipColorTheme]="'dark'"
										data-toggle="dropdown"
										aria-haspopup="true"
										aria-expanded="false"
										[ngClass]="showPeriodDropdown ? 'prompt-expanded' : ''"
									>
										<span>
											{{ assortmentCtrl.period }}
										</span>
									</a>
									<ng-template #periodTooltipContent>
										<div style="padding: 9px 14px; color: #000000">
											{{ pageHoverTexts.periodTooltip }}
										</div>
									</ng-template>
									<app-dropdown-menu
										[hidden]="!showPeriodDropdown"
										[items]="periodList"
										(selectedItem)="periodSelected($event)"
										[configId]="qid"
										[selectorType]="5"
										(onDefaultChange)="onMoreOptionClicked($event)"
									></app-dropdown-menu>
								</span>
							</div>
						</div>
					</div>
				</div>
				<crf-ca-export-report
					(onExport)="reportDownload()"
					[pptIcon]="false"
				></crf-ca-export-report>

				<crf-ca-save-default
					class="save-selection-all"
					[selectorType]="7"
					[configId]="qid"
					(menuSelector)="onMoreOptionClicked($event)"
				>
				</crf-ca-save-default>
			</div>
		</div>
	</div>
	<div *ngIf="!requestService.isInnerTable" class="assortment-dashboard-top-layout ng-scope">
		<div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
			<div class="panel panel-default">
				<div class="header-block">Walmart in <span>Selected Market</span></div>
				<hr />
				<div class="header2-block">UPC COUNTS</div>
				<div class="count-table">
					<crf-ag-grid
						[customStyle]="'height: 28vh'"
						class="ag-theme-alpine"
						[rowData]="upcCountsRowData"
						[columns]="upcCountsColumnDefs"
					></crf-ag-grid>
				</div>
				<div class="header2-block">Walmart % of TM</div>
				<div class="total-table">
					<div class="table-wrapper">
						<crf-ag-grid
							[customStyle]="'height: 25vh'"
							class="ag-theme-alpine"
							[rowData]="upcPercentageRowData"
							[columns]="upcPercentageColumnDefs"
						></crf-ag-grid>
					</div>
				</div>
			</div>
		</div>
		<div class="col-lg-8 col-md-8 col-sm-8 col-xs-12">
			<div class="panel panel-default">
				<div class="header-block">
					New, Existing &amp; Delisted for <span>{{ selectedProductToShow.value }}</span> for
					<span> {{ selectedMarketCount > 1 ? 'Walmart Markets' : 'Walmart Market' }}</span>
				</div>
				<hr />
				<div class="mkt-table-container">
					<div class="watermark watermark-top">Remaining Market UPC Status</div>
					<div class="watermark watermark-left">Walmart UPC Status</div>
					<div class="mkt-table">
						<div *ngIf="tableLoader">
							<span class="spinner-large">
								<!-- <em class="fa fa-circle-o-notch fa-spin fa-3x fa-fw"></em> -->
								<niq-spinner colorTheme="light" size="medium" variant="brand"></niq-spinner>
							</span>
						</div>
						<crf-ag-grid
							[customStyle]="'height: 50vh;font-weight:400'"
							class="ag-theme-alpine"
							[rowData]="mktTableRowData"
							[columns]="mktTableColumnDefs"
							(cellClick)="showInDetailData($event.data, $event.column.colId)"
						></crf-ag-grid>
					</div>
				</div>
			</div>
		</div>
		<!-- <div class="row mt-2">
		<div class="col-12">
			<crf-ca-footer-performance-quadrant-report></crf-ca-footer-performance-quadrant-report>
		</div>
	</div> -->
	</div>
	<!-- Inner Table in Detail -->
	<div *ngIf="requestService.isInnerTable">
		<crf-ca-assortment-wmvs-rm-inner-table
			[tabsList]="tabs"
			[pinnedTopRowData]="pinnedTopRowData"
			(colDefsChanged)="onColDefsChanged($event)"
		></crf-ca-assortment-wmvs-rm-inner-table>
	</div>
	<div class="col-xs-12 footnote-block">
		<crf-ca-dashboard-footnote [reportDimension]="assortmentCtrl"> </crf-ca-dashboard-footnote>
	</div>
</div>
