import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { forkJoin, map, Subject, takeUntil } from 'rxjs';
import { PANEL_DASHBOARD } from 'src/app/constants/dashboard-ui.constant';
import { pageHoverTexts, SELECTOR_TYPES } from 'src/app/models/report-hovertexts.constant';
import { PanelRequestData, ReportDimension } from 'src/app/models/report-dimension.model';
import { PanelApiService } from '../services/panel-api.service';
import { UtilityService } from '../services/utility.service';
import { ToastService } from '@nielseniq/athena-core';
import { TOAST } from 'src/app/constants/toast.constant';
import { TextChangePipe } from 'src/app/pipes/text-change.pipe';
import * as _ from 'lodash';
import * as d3 from 'd3';
import { TopbarUtilityService } from 'src/app/core/service/topbar/topbar-utility.service';
import { ProductSearchService } from 'src/app/service/product-search.service';
@Component({
	selector: 'crf-ca-panel-dashboard',
	templateUrl: './panel-dashboard.component.html',
	styleUrl: './panel-dashboard.component.scss'
})
export class PanelDashboardComponent implements OnInit, AfterViewInit, OnDestroy {
	showDropdown = {
		product: false,
		shareTo: false,
		market: false,
		period: false,
		compPeriod: false
	};
	qid: number = 3;
	panelDashboardCtrl: ReportDimension = {
		dashboardName: 'Shopper Overview (Panel)',
		selectedMarket: [],
		twoYAEnabled: true,
		hideText2: true
	};
	shareToSelectorList: any = [
		{ key: 'RETAILER', value: 'Retailer' },
		{ key: 'CHANNEL', value: 'Channel' }
	];
	marketList: any;
	marketMenuItems: any = [];
	periodList: any = [];
	periodData: any = {};
	compPeriodList: any = [
		{ key: 'YA', value: 'YA' },
		{ key: '2YA', value: '2YA' }
	];
	selectedProductToShow: { level: string; value: string } = {
		level: '',
		value: ''
	};

	requestPayload: PanelRequestData = {
		brand: '',
		category: '',
		categoryGroup: '',
		comparisonPeriod: '',
		cyPeriod: '',
		department: '',
		majorBusiness: '',
		market: [],
		shareTo: '',
		totalBusiness: '',
		yaPeriod: ''
	};

	pageHoverTexts: any = pageHoverTexts;
	selectorTypes = SELECTOR_TYPES;
	private unSubscribeAll: Subject<void> = new Subject();

	pieChartDivWidth: number;
	pieChartDivHeight: number;
	waterfallChartWidth: number;
	waterFallChartHeight: number;

	isDataAvailable: boolean;
	MAX_LENGTH_IN_TABLE = 12;
	showRawOccasionsMessage: any;
	completeListSow = [];
	totalOutletSow: any;
	isPanelDashboardInfoReady: boolean = false;
	showRawOccasionsMessageExpandedView: boolean;
	orderByField: any;
	columnDefs = {
		wmShareWallet: 'WM Share of Wallet',
		buyingHouseholds: 'Buying Households',
		dollarVolume: 'Purchase $',
		value: 'PTS Gained / Lost'
	};
	expandableTable: any;
	product_latest_sort = { name: 'WM Share of Wallet', direction: 'desc' };

	selProduct = '';
	selectedProducts = '';
	selectedLevel = '';
	shareOfWalmart = '';
	currentShareOfWalmartToTotalOutlets: any = '';
	previousShareOfWalmartToTotalOutlets = '';
	top3Gained: any;
	top3Lost: any;

	penetrationCYValue = '';
	buyingRateCYValue = '';
	frequencyCYValue = '';
	tripCYValue = '';
	buyingHouseholdsValue = '';
	penetrationChangeValue = '';
	buyingRateChangeValue = '';
	frequencyChangeValue = '';
	tripChangeValue = '';
	shareOfWalletExpanded = false;

	// Share of wallet dashboard section
	shareToSelectorHoverText = PANEL_DASHBOARD.SHARE_TO_SELECTOR_HOVER_TEXT;
	expandShareWalletHoverText = PANEL_DASHBOARD.EXPAND_SHARE_WALLET_HOVER_TEXT;
	contractShareWalletHoverText = PANEL_DASHBOARD.CONTRACT_SHARE_WALLET_HOVER_TEXT;
	chartBottomLegend = PANEL_DASHBOARD.CHART_BOTTOM_LEGEND;
	gained = PANEL_DASHBOARD.GAINED;
	lost = PANEL_DASHBOARD.LOST;
	million = PANEL_DASHBOARD.MILLION;
	dueTo = PANEL_DASHBOARD.DUETO;
	numberOf = PANEL_DASHBOARD.THE_NUMBER_OF;
	valueCurrent = PANEL_DASHBOARD.VALUE_CURRENT;

	// Share of Wallet expanded Table
	shareWalletTableTitle = PANEL_DASHBOARD.SHARE_WALLET_TABLE_TITLE;
	retailerColumn = PANEL_DASHBOARD.RETAILER_COLUMN;
	buyingHouseholdsColumn = PANEL_DASHBOARD.BUYING_HOUSEHOLDS_COLUMN;
	purchaseColumn = PANEL_DASHBOARD.PURCHASE_COLUMN;
	vmShareColumn = PANEL_DASHBOARD.VM_SHARE_COLUMN;
	bpsColumn = PANEL_DASHBOARD.BPS_COLUMN;
	// selectors
	productSelectorHoverText = PANEL_DASHBOARD.PRODUCT_SELECTOR_HOVER_TEXT;
	marketSelectorHoverText = PANEL_DASHBOARD.MARKET_SELECTOR_HOVER_TEXT;
	periodSelectorHoverText = PANEL_DASHBOARD.PERIOD_SELECTOR_HOVER_TEXT;
	comparisonPeriodSelectorHoverText = PANEL_DASHBOARD.COMPARISON_PERIOD_SELECTOR_HOVER_TEXT;
	rawBuyersThreshold = PANEL_DASHBOARD.RAW_BUYERS_THRESHOLD;
	rawOccationsThreshold = PANEL_DASHBOARD.RAW_OCCASIONS_THRESHOLD;
	// purchase dynamics section
	purchaseDynamicsText = PANEL_DASHBOARD.PURCHASE_DYNAMICS;
	penetrationText = PANEL_DASHBOARD.PENETRATION;
	buyingRateText = PANEL_DASHBOARD.BUYING_RATE;
	frequencyText = PANEL_DASHBOARD.FREQUENCY;
	dollarPerTripText = PANEL_DASHBOARD.DOLLAR_PER_TRIP;
	changeYAText = PANEL_DASHBOARD.CHANGE_YA;
	changePtsText = PANEL_DASHBOARD.CHANGE_PTS;
	buyingHouseholdsText = PANEL_DASHBOARD.BUYING_HOUSEHOLDS;
	penetrationLowerCaseText = PANEL_DASHBOARD.PENETRATION_LOWER_CASE;
	percentExtension = PANEL_DASHBOARD.PERCENT_EXT;

	// share of wallet section
	shareOfWalletBlockTitleText = PANEL_DASHBOARD.SHARE_OF_WALLET_BLOCK_TITLE;
	shareOfWalletText = PANEL_DASHBOARD.SHARE_OF_WALLET;
	shareOfWalletChangeText = PANEL_DASHBOARD.SHARE_OF_WALLET_CHG;
	vsYaText = PANEL_DASHBOARD.VS_YA;
	dollarExtension = PANEL_DASHBOARD.DOLLAR_EXT;

	showOverlayMsg: string;
	showOverlay: boolean = false;
	shareOfWalmartStyle: string;
	shareOfWalmartIsRawBuyers: string;
	penetrationChangeStyle: string;
	buyingRateChangeStyle: string;
	frequencyChangeStyle: string;
	tripChangeStyle: string;
	reverse = true;
	propertyNameOrdered = 'wmShareWallet';
	pageTitle: any;

	constructor(
		private panelApiService: PanelApiService,
		private utilityService: UtilityService,
		private toastService: ToastService,
		private textChangePipe: TextChangePipe,
		private topBarUtility: TopbarUtilityService,
		private productService: ProductSearchService
	) {}

	ngOnInit(): void {
		this.pageTitle = this.topBarUtility.getPageTitleReport();
		this.panelDashboardCtrl.selectedLevelToShow = this.shareToSelectorList[0].value;
		this.onInitialLoad();
	}
	ngAfterViewInit() {
		this.getChartsDivWidth();
	}

	onInitialLoad() {
		const requestArray = [
			this.utilityService.getDefaultSelectors(this.qid),
			this.panelApiService.getAllPanelMarkets(),
			this.panelApiService.getAllPanelPeriods()
		];
		forkJoin(requestArray)
			.pipe(
				map(([defaultAll, allMarket, allPeriod]) => {
					return {
						defaultAll: defaultAll,
						allMarket: allMarket,
						allPeriod: allPeriod
					};
				})
			)
			.subscribe({
				next: response => {
					if (response['allMarket']) {
						this.marketList = response['allMarket'];
						Object.entries(this.marketList).map(market => {
							let marketEntry = { key: market[0], value: market[1], isSelected: false };
							this.marketMenuItems.push(marketEntry);
						});
					}

					if (response['allPeriod']) {
						this.periodData.periods = response['allPeriod'];
						Object.keys(this.periodData.periods).forEach(item => {
							if (this.periodData.periods[item].defaultPeriod) {
								this.periodData.default_key = item;
								this.periodData.currentPeriod = this.periodData.periods[item].currentPeriod;
								this.periodData.previousPeriod = this.periodData.periods[item].previousPeriod;
								this.periodData.twoYAPeriod = this.periodData.periods[item].twoYAPeriod;
								this.periodData.id = this.periodData.periods[item].id;
							}
							this.periodList.push({ key: this.periodData.periods[item].id, value: item });
						});
					}
					if (response['defaultAll']) {
						this.panelApiService.defaultSelectors = response['defaultAll'];
						this.onApplyDefault(response['defaultAll'], 7);
						this.getPanelDashboardInfo();
					}
				},
				error: error => {
					console.log('Error occured', error);
				}
			});
	}

	onApplyDefault(response, selector) {
		let selectedValueObj = {
			totalbusiness: 'TOTAL_BUSINESS',
			majorbusiness: 'MAJOR_BUSINESS',
			department: 'DEPARTMENT',
			categorygroup: 'CATEGORY_GROUP',
			category: 'CATEGORY',
			brand: 'BRAND'
		};
		let selectedValueAlias = {
			totalbusiness: 'TOTAL',
			majorbusiness: 'MAJOR',
			department: 'DEPT',
			categorygroup: 'CATGRP',
			category: 'CAT',
			brand: 'BRAND'
		};
		let payload;
		if (selector === 3 || selector === 7) {
			let selectedLevel;
			this.panelDashboardCtrl.selectedProduct = [];
			if (response?.totalbusiness) {
				this.panelDashboardCtrl.selectedProduct.push({
					displayName: 'Total Business',
					displayValue: [response.totalbusiness]
				});
				this.requestPayload.totalBusiness = response.totalbusiness;
				selectedLevel = 'totalbusiness';
			}
			if (response?.majorbusiness) {
				this.panelDashboardCtrl.selectedProduct.push({
					displayName: 'Major Business',
					displayValue: [response.majorbusiness]
				});
				this.requestPayload.majorBusiness = response.majorbusiness;
				selectedLevel = 'majorbusiness';
			}
			if (response?.department) {
				this.panelDashboardCtrl.selectedProduct.push({
					displayName: 'Department',
					displayValue: [response.department]
				});
				this.requestPayload.department = response.department;
				selectedLevel = 'department';
			}
			if (response?.categorygroup) {
				this.panelDashboardCtrl.selectedProduct.push({
					displayName: 'Category Group',
					displayValue: [response.categorygroup]
				});
				this.requestPayload.categoryGroup = response.categorygroup;
				selectedLevel = 'categorygroup';
			}
			if (response?.category) {
				this.panelDashboardCtrl.selectedProduct.push({
					displayName: 'Category',
					displayValue: [response.category]
				});
				this.requestPayload.category = response.category;
				selectedLevel = 'category';
			}
			if (response?.brand) {
				this.panelDashboardCtrl.selectedProduct.push({
					displayName: 'Brand Type',
					displayValue: [response.brand]
				});
				this.requestPayload.brand = response.brand;
			}
			this.selectedProductToShow = {
				level: selectedValueObj[selectedLevel],
				value: response[selectedLevel]
			};
			payload = {
				alias_name: selectedValueAlias[selectedLevel],
				key: selectedValueObj[selectedLevel],
				value: response[selectedLevel]
			};
			this.containFreshProduct(payload);
		}

		if (selector === 1 || selector === 7) {
			this.panelDashboardCtrl.selectedLevelToShow = response.byLevel
				? response.byLevel
				: this.shareToSelectorList[0].value;
			this.utilityService.preselectDefault(
				this.shareToSelectorList,
				this.panelDashboardCtrl.selectedLevelToShow
			);
			this.requestPayload.shareTo = this.panelDashboardCtrl.selectedLevelToShow;
		}

		if (selector === 2 || selector === 7) {
			this.marketMenuItems = [];
			this.panelDashboardCtrl.selectedMarket = [];
			this.requestPayload.market = [];
			if (response.market === null) {
				Object.entries(this.marketList).map(market => {
					let marketEntry = { key: market[0], value: market[1], isSelected: false };
					this.marketMenuItems.push(marketEntry);
				});
				this.panelDashboardCtrl.selectedMarket.push('Total US');
				this.requestPayload.market.push(this.marketList['Total US'].walmartMarket);
			} else {
				Object.entries(this.marketList).map(market => {
					let marketEntry = {
						key: market[0],
						value: market[1],
						isSelected: this.utilityService
							.defaultSelectorSplit(response.market)
							.includes(market[0])
					};
					this.marketMenuItems.push(marketEntry);
				});
				let selectedMarket = response.market ? response.market.split(';') : [];
				this.panelDashboardCtrl.selectedMarket.push(selectedMarket[2]);
				this.requestPayload.market.push(selectedMarket[0]);
			}
		}

		if (selector === 8 || selector === 7) {
			this.panelDashboardCtrl.comparisonPeriod = response.comparisonPeriod
				? response.comparisonPeriod
				: this.compPeriodList[0].value;
			this.requestPayload.comparisonPeriod = this.panelDashboardCtrl.comparisonPeriod;
			this.utilityService.preselectDefault(
				this.compPeriodList,
				this.panelDashboardCtrl.comparisonPeriod
			);
		}

		if (selector === 5 || selector === 7) {
			this.panelDashboardCtrl.period = response.periodIds
				? response.periodIds
				: this.periodData.default_key;
			this.requestPayload.cyPeriod =
				this.periodData.periods[this.panelDashboardCtrl.period].currentPeriod;
			this.utilityService.preselectDefault(this.periodList, this.panelDashboardCtrl.period);
		}

		if (this.panelDashboardCtrl?.comparisonPeriod && this.panelDashboardCtrl.period) {
			this.requestPayload.yaPeriod =
				this.panelDashboardCtrl?.comparisonPeriod === 'YA'
					? this.periodData.periods[this.panelDashboardCtrl.period].previousPeriod
					: this.periodData.periods[this.panelDashboardCtrl.period].twoYAPeriod;
		}
	}

	onMoreOptionClicked(data) {
		if (data.menuId === 1) {
			let selectorType = this.selectorTypes[data.selectorId].type;
			let selectorValues = this.saveSelectionMetaData();
			let payload = this.getSaveDefaultPayload(data.menuId, data.selectorId, selectorValues);
			let queryParam = this.qid + '?type=' + selectorType;
			if (selectorType !== 'all') {
				this.panelApiService.defaultSelectors[selectorType] = selectorValues[selectorType];
			} else {
				this.panelApiService.defaultSelectors = {
					brand: this.requestPayload.brand,
					category: this.requestPayload.category,
					categorygroup: this.requestPayload.categoryGroup,
					department: this.requestPayload.department,
					majorbusiness: this.requestPayload.majorBusiness,
					totalbusiness: this.requestPayload.totalBusiness,
					byLevel: selectorValues.byLevel,
					comparisonPeriod: selectorValues.comparisonPeriod,
					market: selectorValues.market,
					periodIds: selectorValues.periodIds
				};
			}
			this.utilityService.saveItemAsDefault(payload, queryParam).subscribe({
				next: res => {
					this.toastService.InjectToast(
						'success',
						'Selections saved successfully',
						'',
						TOAST.TIMEOUT,
						'',
						'Success',
						TOAST.SIZE
					);
				},
				error: err => console.log(err)
			});
		} else if (data.menuId === 2) {
			if (data.selectorId === 7 || data.selectorId === 3) {
				this.requestPayload.brand = '';
				this.requestPayload.category = '';
				this.requestPayload.categoryGroup = '';
				this.requestPayload.department = '';
				this.requestPayload.majorBusiness = '';
				this.requestPayload.totalBusiness = '';
				this.utilityService
					.getDefaultSelectors(this.qid)
					.pipe(takeUntil(this.unSubscribeAll))
					.subscribe(response => {
						this.panelApiService.defaultSelectors = response;
						this.onApplyDefault(response, 7);
						this.getPanelDashboardInfo();
					});
			} else {
				this.onApplyDefault(this.panelApiService.defaultSelectors, data.selectorId);
				this.getPanelDashboardInfo();
			}
		}
	}

	saveSelectionMetaData() {
		let selMarket = this.panelDashboardCtrl.selectedMarket[0];
		return {
			market:
				this.marketList[selMarket].walmartMarket +
				';' +
				this.marketList[selMarket].remainingMarket +
				';' +
				selMarket,
			byLevel: this.panelDashboardCtrl.selectedLevelToShow,
			periodIds: this.panelDashboardCtrl.period,
			comparisonPeriod: this.panelDashboardCtrl.comparisonPeriod
		};
	}

	getSaveDefaultPayload(type, selectorId, params) {
		if (type === 1) {
			let paramsObj = {};
			if (selectorId === 0 || selectorId === 7) {
				paramsObj = {
					brand: this.requestPayload.brand,
					brandFamily: '',
					brandHigh: '',
					brandLow: '',
					brandOwnerHigh: '',
					brandOwnerLow: '',
					category: this.requestPayload.category,
					categoryGroup: this.requestPayload.categoryGroup,
					department: this.requestPayload.department,
					majorBusiness: this.requestPayload.majorBusiness,
					segment: '',
					subCategory: '',
					totalBusiness: this.requestPayload.totalBusiness
				};
			}
			for (let i in this.selectorTypes) {
				if ((selectorId == Number(i) || selectorId === 7) && i !== '0') {
					if (params[this.selectorTypes[i].type] !== undefined) {
						paramsObj[this.selectorTypes[i].type] = params[this.selectorTypes[i].type];
					}
				}
			}
			return paramsObj;
		}
	}

	toggleDropdown(dropdown: string) {
		this.showDropdown[dropdown] = !this.showDropdown[dropdown];
	}

	closeDropdown(dropdown: string) {
		this.showDropdown[dropdown] = false;
	}

	containFreshProduct(product) {
		delete product?.payloadField;
		this.productService.findFreshProducts(product).subscribe(response => {
			if (response.data.every(e => e.flag === '0')) {
				this.panelDashboardCtrl.freshProduct = false;
			} else {
				this.panelDashboardCtrl.freshProduct = true;
			}
		});
	}

	updateProductSelection(selectedProduct) {
		this.containFreshProduct({ ...selectedProduct?.selectedProduct });
		selectedProduct?.optedValues.forEach(level => {
			let key = level.payloadField.toLowerCase();
			if (selectedProduct.saveDefault) this.panelApiService.defaultSelectors[key] = level.value;
			if (this.requestPayload.hasOwnProperty(level.payloadField))
				this.requestPayload[level.payloadField] = level.value;
		});
		this.getPanelDashboardInfo();
		this.selectedProductToShow = {
			level: selectedProduct?.selectedProduct?.key,
			value: selectedProduct?.selectedProduct?.value
		};
		this.panelDashboardCtrl.selectedProduct = [];
		selectedProduct?.optedValues
			.filter(item => item.value !== '' && item.value !== null)
			.map(item => {
				this.panelDashboardCtrl.selectedProduct.push({
					displayName: this.textChangePipe.transform(item.alias_name, this.qid),
					displayValue: [item.value]
				});
			});
	}

	byShareSelected(data: any) {
		this.panelDashboardCtrl.selectedLevelToShow = data.value;
		this.requestPayload.shareTo = data.value;
		this.getPanelDashboardInfo();
		this.showDropdown.shareTo = false;
	}

	marketSelected(selectedMarket: any) {
		this.panelDashboardCtrl.selectedMarket = [];
		this.requestPayload.market = [];
		this.panelDashboardCtrl.selectedMarket.push(selectedMarket[0].key);
		this.requestPayload.market.push(selectedMarket[0].value.walmartMarket);
		this.getPanelDashboardInfo();
		this.showDropdown.market = false;
	}

	periodSelected(data: any) {
		this.panelDashboardCtrl.period = data.value;
		this.requestPayload.cyPeriod = this.periodData.periods[data.value].currentPeriod;
		this.requestPayload.yaPeriod =
			this.panelDashboardCtrl.comparisonPeriod === 'YA'
				? this.periodData.periods[data.value].previousPeriod
				: this.periodData.periods[data.value].twoYAPeriod;
		this.getPanelDashboardInfo();
		this.showDropdown.period = false;
	}

	compPeriodSelected(data: any) {
		let selectedPeriod = this.periodData.periods[this.panelDashboardCtrl.period];
		this.panelDashboardCtrl.comparisonPeriod = data.value;
		this.requestPayload.comparisonPeriod = data.value;
		this.requestPayload.yaPeriod =
			data.value === 'YA' ? selectedPeriod.previousPeriod : selectedPeriod.twoYAPeriod;
		this.getPanelDashboardInfo();
		this.showDropdown.compPeriod = false;
	}

	levelMappingToApiRequest(level) {
		switch (level) {
			case 'TOTAL_BUSINESS':
				return 'totlabusiness';
			case 'MAJOR_BUSINESS':
				return 'majorbusiness';
			case 'DEPARTMENT':
				return 'department';
			case 'CATEGORY_GROUP':
				return 'categorygroup';
			case 'CATEGORY':
				return 'Category';
			case 'BRAND':
				return 'brand';
		}
	}

	reportDownload(downloadType: 'excel' | 'ppt') {
		let reportData = {
			applicationType: 'JBP',
			dataModelType: 'CONTRIBUTIONDRIVERS',
			productName: this.selectedProductToShow.value,
			marketName: this.panelDashboardCtrl.selectedMarket,
			selectedLevel: this.levelMappingToApiRequest(this.selectedProductToShow.level),
			shareTo: this.panelDashboardCtrl.selectedLevelToShow,
			bylevel: this.panelDashboardCtrl.selectedLevelToShow,
			market: this.requestPayload.market,
			period: this.periodData.periods[this.panelDashboardCtrl.period].id,
			brand: this.requestPayload.brand || '',
			department: this.requestPayload.department || '',
			categoryGroup: this.requestPayload.categoryGroup || '',
			category: this.requestPayload.category || '',
			totalBusiness: this.requestPayload.totalBusiness || '',
			majorBusiness: this.requestPayload.majorBusiness || '',
			periodEndDate: this.panelDashboardCtrl.periodEndDate,
			cyPeriod: this.requestPayload.cyPeriod,
			yaPeriod: this.requestPayload.yaPeriod,
			scope: this.selectedProductToShow.level,
			scopeDisplayName: this.textChangePipe.transform(this.selectedProductToShow.level),
			selectedValue: this.selectedProductToShow.value,
			level: this.panelDashboardCtrl.selectedLevelToShow,
			comparisonPeriod: this.panelDashboardCtrl.comparisonPeriod
		};
		this.utilityService.exportFile(reportData, downloadType);
	}

	getPanelDashboardInfo() {
		this.checkLowSampleSizeMarkets();
		this.isPanelDashboardInfoReady = false;
		this.panelApiService.getPanelDashboardInfo(this.requestPayload).subscribe(res => {
			if (!this.utilityService.isValid(res)) {
				this.isDataAvailable = false;
				this.showOverlay = true;
				this.showOverlayMsg = 'Data Not Available';
				this.isPanelDashboardInfoReady = true;
				this.emptyFields();
			} else {
				this.showOverlay = false;
				this.isDataAvailable = true;
				let purchaseDynamics = res['Purchase Dynamics'];

				for (let i = 0; i < purchaseDynamics.length; i++) {
					switch (purchaseDynamics[i].driver) {
						case 'Penetration CY':
							this.penetrationCYValue = purchaseDynamics[i].value + this.percentExtension;
							break;
						case 'Buying Rate CY':
							this.buyingRateCYValue = purchaseDynamics[i].value;
							break;
						case 'Frequency CY':
							this.frequencyCYValue = purchaseDynamics[i].value;
							break;
						case 'Trip CY':
							this.tripCYValue = purchaseDynamics[i].value;
							break;
						case 'Penetration Change':
							this.penetrationChangeValue = purchaseDynamics[i].value;
							break;
						case 'Buying Rate Change':
							this.buyingRateChangeValue = purchaseDynamics[i].value;
							break;
						case 'Frequency Change':
							this.frequencyChangeValue = purchaseDynamics[i].value;
							break;
						case 'Trip Change':
							this.tripChangeValue = purchaseDynamics[i].value;
							break;
						case 'Buying HouseHolds':
							this.buyingHouseholdsValue = this.numberWithCommas(purchaseDynamics[i].value);
							break;
					}
				}

				// getting font color
				this.penetrationChangeStyle = this.getFontColorStyle(this.penetrationChangeValue);
				this.buyingRateChangeStyle = this.getFontColorStyle(this.buyingRateChangeValue);
				this.frequencyChangeStyle = this.getFontColorStyle(this.frequencyChangeValue);
				this.tripChangeStyle = this.getFontColorStyle(this.tripChangeValue);

				// formatting values
				this.buyingRateCYValue = this.formatWithDollar(this.buyingRateCYValue);
				this.tripCYValue = this.formatWithDollar(this.tripCYValue);
				this.buyingRateChangeValue = this.formatWithDollar(this.buyingRateChangeValue);
				this.tripChangeValue = this.formatWithDollar(this.tripChangeValue);

				let shareOfWallet = res['Share Of Wallet'];
				this.shareOfWalmart = shareOfWallet.shareofWalmart;
				this.shareOfWalmartStyle = this.getFontColorStyle(this.shareOfWalmart);
				this.currentShareOfWalmartToTotalOutlets =
					shareOfWallet.currentShareOfWalmartToTotalOutlets === 'RO'
						? 'RO'
						: parseFloat(shareOfWallet.currentShareOfWalmartToTotalOutlets).toFixed(1);
				this.shareOfWalmartIsRawBuyers = shareOfWallet.walmartRawBuyer;
				this.previousShareOfWalmartToTotalOutlets =
					shareOfWallet.previousShareOfWalmartToTotalOutlets;

				this.top3Gained = shareOfWallet.top3Gained;
				for (let j = 0; j < this.top3Gained.length; j++) {
					if (this.top3Gained[j].driver.length > this.MAX_LENGTH_IN_TABLE) {
						this.top3Gained[j].hoverText = this.top3Gained[j].driver;
						this.top3Gained[j].driver =
							this.top3Gained[j].driver.substring(0, this.MAX_LENGTH_IN_TABLE) + '...';
					}
				}

				this.top3Lost = shareOfWallet.top3Lost;
				for (let k = 0; k < this.top3Lost.length; k++) {
					if (this.top3Lost[k].driver.length > this.MAX_LENGTH_IN_TABLE) {
						this.top3Lost[k].hoverText = this.top3Lost[k].driver;
						this.top3Lost[k].driver =
							this.top3Lost[k].driver.substring(0, this.MAX_LENGTH_IN_TABLE) + '...';
					}
				}
				this.showRawOccasionsMessage = this.checkIfShowRawOccasionsMessage();
				let periodEndDate: any = new Date(res['Period End Date'].trim());
				let options = {
					month: 'short'
				};
				let month = periodEndDate.toLocaleDateString('en-US', options);
				this.panelDashboardCtrl.periodEndDate =
					month + '-' + periodEndDate.getDate() + '-' + periodEndDate.getFullYear();

				this.completeListSow = shareOfWallet.completeList;
				this.appendInfoForCompleteListSow();

				this.totalOutletSow = shareOfWallet.totalOutletSOW;
				this.appendInfoForTotalOutletSow();

				if (res && res['Contribution Drivers']) {
					let item = res['Contribution Drivers'].find(e => e.driver === 'Purchase $ YA');
					item.driver = item.driver.replace('YA', this.panelDashboardCtrl?.comparisonPeriod);
				}

				this.waterFallChartInit(res, 'conWaterFallGraph', this.dollarExtension);
				if (this.panelDashboardCtrl.selectedLevelToShow === 'Retailer') {
					this.pieChartInit(res, 'shareOfWalmartPieChart', this.vsYaText, this.dollarExtension);
				} else {
					this.channelsPieChartInit(res, 'channelsPieChart', this.vsYaText, this.dollarExtension);
				}
				this.isPanelDashboardInfoReady = true;
			}
		});
	}

	getChartsDivWidth() {
		this.pieChartDivWidth = document.getElementById('shareOfWalmartPieChart').offsetWidth;
		this.pieChartDivHeight = document.getElementById('shareOfWalmartPieChart').offsetHeight;
		this.waterfallChartWidth = document.getElementById('conWaterFallGraph').offsetWidth;
		this.waterFallChartHeight = document.getElementById('conWaterFallGraph').offsetHeight;
	}

	waterFallChartInit(receivedData, containerId, dolexten) {
		d3.select('#hiddenSVG').remove();
		let chartWidth, chartHeight;
		let width = this.waterfallChartWidth;
		let height = document.getElementById(containerId).offsetHeight;
		if (height === 0) height = this.waterFallChartHeight;
		let svg = d3
			.select('#' + containerId)
			.append('svg')
			.attr(':xmlns:svg', 'http://www.w3.org/2000/svg')
			.attr('id', 'hiddenSVG')
			.attr('width', width - 50)
			.attr('height', height)
			.attr('style', 'padding-top: 20px');
		let axisLayer = svg.append('g').classed('axisLayer', true);
		let chartLayer = svg.append('g').classed('chartLayer', true);
		let xScale = d3.scaleBand();
		let yScale = d3.scaleLinear();

		let data = receivedData['Contribution Drivers'];

		let yAxisMinRange = receivedData['Range'].min;
		let tempMinVal = yAxisMinRange;
		let newTempMinVal = tempMinVal;
		if (newTempMinVal < 100) {
			newTempMinVal = 0;
		}
		let yAxisMaxRange = receivedData['Range'].max;

		let cumulative = 0;
		data.pop(); // removes the last element (value this year) from JSON
		// since its calculated here
		for (let i = 0; i < data.length; i++) {
			data[i].value = parseFloat(data[i].value);
			data[i].start = cumulative;
			cumulative += data[i].value;
			data[i].end = cumulative;

			data[i].class = data[i].value >= 0 ? 'positive' : 'negative';

			if (i === 0) {
				data[i].start = parseFloat(newTempMinVal);
				data[i].class = 'first-rect';
			} else {
				if (data[i].driver === 'Frequency' || data[i].driver === 'Avg. Price per Unit') {
					data[i].tooltipText =
						this.dollarFormatter(data[i].value, dolexten) +
						this.million +
						(data[i].value >= 0 ? this.gained : this.lost) +
						this.dueTo +
						data[i].driver;
				} else {
					data[i].tooltipText =
						this.dollarFormatter(data[i].value, dolexten) +
						this.million +
						(data[i].value >= 0 ? this.gained : this.lost) +
						this.dueTo +
						this.numberOf +
						data[i].driver;
				}
			}
		}

		data.push({
			driver: this.valueCurrent,
			end: cumulative,
			start: parseFloat(newTempMinVal),
			class: 'total',
			value: cumulative
		});

		let margin1 = {
			top: 0,
			left: 140,
			bottom: 20,
			right: 0
		};
		chartWidth = width - (margin1.left + margin1.right);
		chartHeight = height - (margin1.top + margin1.bottom) - 50;

		axisLayer.attr('width', width).attr('height', height - 50);

		chartLayer
			.attr('width', chartWidth)
			.attr('height', chartHeight)
			.attr('transform', 'translate(' + [margin1.left - 50, 9] + ')');

		let xAxis = d3.axisBottom(xScale);
		let yAxis = d3
			.axisLeft(yScale)
			.ticks(12)
			.tickFormat(d => '$' + d)
			.tickSizeInner(-chartWidth)
			.tickPadding(20);

		xScale
			.domain(data.map(d => d.driver))
			.range([0, chartWidth])
			.paddingInner(0.4)
			.paddingOuter(0.5);

		yScale.domain([parseInt(newTempMinVal), yAxisMaxRange]).range([chartHeight - 20, 0]);

		let bar = chartLayer
			.selectAll('.bar')
			.data(data)
			.enter()
			.append('g')
			.attr('class', d => 'bar ' + d.class)
			.attr('transform', (d, j) => {
				let transXValue = xScale(d.driver);
				return 'translate(' + transXValue + ',0)';
			});

		let div = d3
			.select('#' + containerId)
			.append('div')
			.attr('class', 'popover top panel-dashboard-only-popover')
			.style('display', 'none');

		bar
			.append('rect')
			.attr('y', d => yScale(Math.max(d.start, d.end)))
			.attr('height', d => Math.abs(yScale(d.start) - yScale(d.end)))
			.attr('width', xScale.bandwidth());

		bar
			.append('text')
			.attr('text-anchor', 'middle')
			.style('font-weight', '600')
			.style('font-size', '15px')
			.attr('x', xScale.bandwidth() / 2)
			.attr('y', d => yScale(Math.max(d.start, d.end)) - 5)
			.text(d => this.dollarFormatter(d.value, dolexten))
			.on('mouseover', (d, event) => {
				if (event.tooltipText !== '' && event.tooltipText !== undefined) {
					div.transition().duration(200).style('display', 'block');
					div
						.html(
							"<div class='arrow'></div><div class='popover-content'>" +
								event.tooltipText +
								'</div>'
						)
						.style('left', d.pageX - 128 + 'px')
						.style('top', d.pageY - 517 + 'px');
				}
			})
			.on('mouseout', (d, event) => {
				if (event.tooltipText !== '' && event.tooltipText !== undefined) {
					div.transition().duration(500).style('display', 'none');
				}
			});

		bar
			.filter(d => d.class !== 'total')
			.append('line')
			.attr('class', 'connector')
			.attr('x1', xScale.bandwidth() + 5)
			.attr('y1', d => yScale(d.end))
			.attr('x2', xScale.bandwidth() / (1 - 0.3) - 5)
			.attr('y2', d => yScale(d.end));

		axisLayer
			.append('g')
			.attr('transform', 'translate(' + [margin1.left, 10] + ')')
			.attr('class', 'axis y')
			.call(yAxis);

		axisLayer
			.append('text')
			// this makes it easy to center the text as the transform is applied to the anchor
			.attr('text-anchor', 'middle')
			// text is drawn off the screen top left, move down and out and rotate
			.attr('transform', 'translate(' + 50 + ',' + chartHeight / 2 + ')rotate(-90)')
			.text(receivedData.yAxisTitle)
			.style('font-weight', '600')
			.style('font-size', '15px');

		axisLayer
			.append('g')
			.attr('class', 'axis x')
			.attr('transform', 'translate(' + [margin1.left - 50, chartHeight] + ')')
			.call(xAxis);
	}

	pieChartInit(receivedData, containerId, exten, dolexten) {
		d3.select('#' + containerId)
			.select('svg')
			.remove();
		let containerHeight = document.getElementById(containerId).offsetHeight;
		if (containerHeight === 0) containerHeight = this.pieChartDivHeight;
		let containerWidth = this.pieChartDivWidth;
		let margin = {
			top: 2,
			right: 2,
			bottom: 2,
			left: 2
		};
		let width = containerWidth - margin.left - margin.right;
		let height = containerHeight - margin.top - margin.bottom;
		let pieDataArr = [];
		pieDataArr.push(this.currentShareOfWalmartToTotalOutlets);
		pieDataArr.push(100 - this.currentShareOfWalmartToTotalOutlets);

		let radius = 85;

		let color = d3.scaleOrdinal().range(['#AA55AA', '#552288']);

		let arc = d3
			.arc()
			.innerRadius(radius - 45)
			.outerRadius(radius - 20);

		let pie = d3
			.pie()
			.sort(null)
			.value(d => d);

		let svg = d3
			.select('#' + containerId)
			.append('svg')
			.attr('width', width)
			.attr('height', height)
			.attr('class', 'outerPie')
			.append('g')
			.attr('transform', 'translate(' + width / 2 + ',' + height / 2 + ')');

		let g = svg.selectAll('.arc').data(pie(pieDataArr)).enter().append('g');

		g.append('path')
			.attr('d', arc)
			.attr('fill', (d, i) => color(i));

		if (this.currentShareOfWalmartToTotalOutlets === 'RO') {
			if (this.shareOfWalmartIsRawBuyers) {
				g.append('svg:image')
					.attr('y', -14)
					.attr('x', -22)
					.attr('xlink:href', 'assets/img/niq-icons/warning-small_black.svg');
			}
		} else {
			g.append('text')
				.attr('text-anchor', 'middle')
				.attr('class', 'panel-dashboard-pie-chart-text')
				.attr('y', 5)
				.attr('x', this.shareOfWalmartIsRawBuyers ? 8 : 0)
				.text(this.currentShareOfWalmartToTotalOutlets + '%');
		}
	}

	channelsPieChartInit(receivedData, containerId, exten, dolexten) {
		let channelData = this.completeListSow.slice();
		channelData.sort(function (a, b) {
			return a.wmShareWallet - b.wmShareWallet;
		});

		d3.select('#' + containerId)
			.select('svg')
			.remove();
		let containerHeight = document.getElementById(containerId).offsetHeight;
		if (containerHeight === 0) containerHeight = this.pieChartDivHeight;
		let containerWidth = this.pieChartDivWidth;
		let margin = {
			top: 2,
			right: 2,
			bottom: 2,
			left: 2
		};
		let width = containerWidth - margin.left - margin.right;
		let height = containerHeight - margin.top - margin.bottom;

		let radius = 90;

		let color = d3
			.scaleOrdinal()
			.range([
				'#005d91',
				'#5f86ef',
				'#257d34',
				'#bbae3a',
				'#d97300',
				'#a71d3d',
				'#bb3cae',
				'#852299',
				'#5533a0'
			]);

		let arc = d3
			.arc()
			.innerRadius(radius - 45)
			.outerRadius(radius - 20);

		let pie = d3.pie().value(d => d.wmShareWallet);

		let svg = d3
			.select('#' + containerId)
			.append('svg')
			.attr('width', width)
			.attr('height', height)
			.attr('class', 'outerPie')
			.append('g')
			.attr('transform', 'translate(' + width / 2 + ',' + height / 2 + ')');

		let g = svg.selectAll('.arc').data(pie(channelData)).enter().append('g');

		let popOverdiv = d3
			.select('#' + containerId)
			.append('div')
			.attr('id', 'channels-pie-chart-popover')
			.attr('class', 'popover top panel-dashboard-only-popover')
			.style('display', 'none')

			.html(
				"<div class='arrow'></div>" +
					"<div class='popover-content'>" +
					"<p><span id='channel-name'></span><p>" +
					'<p>' +
					'<span>' +
					this.shareOfWalletText +
					'</span>' +
					"<span class='panel-dashboard-tooltip-right' id='channel-share-wallet'></span>" +
					'</p>' +
					'<p>' +
					'<span>' +
					this.shareOfWalletChangeText +
					'</span>' +
					"<span id='channel-share-wallet-change'></span>" +
					'</p>' +
					'</div>'
			);

		g.append('path')
			.attr('d', arc)
			.attr('fill', (d, i) => color(i));

		let path = svg.selectAll('path');

		path.on('mousemove', (d, event) => {
			popOverdiv.transition().duration(200).style('display', 'block');
			popOverdiv.select('#channel-name').html(event.data.driver);
			popOverdiv
				.select('#channel-share-wallet')
				.html(event.data.wmShareWallet + this.percentExtension);
			popOverdiv
				.select('#channel-share-wallet-change')
				.attr('class', 'panel-dashboard-tooltip-right ' + event.data.valueStyle)
				.html(event.data.value);

			let left = event.layerX;
			let top = event.layerY;

			if (window.navigator.userAgent.includes('.NET')) {
				left -= 129;
				top -= 103;
			} else {
				left -= 105;
				top -= 36;
			}

			popOverdiv.style('left', left + 'px').style('top', top + 'px');
		});

		path.on('mouseout', function () {
			popOverdiv.transition().duration(500).style('display', 'none');
		});
	}

	dollarFormatter(n, exten) {
		let result = parseInt(n);
		if (result < 0) {
			return '-' + exten + this.numberWithCommas(Math.abs(result));
		} else {
			return exten + this.numberWithCommas(result);
		}
	}

	checkLowSampleSizeMarkets() {
		if (
			this.panelDashboardCtrl.selectedMarket[0] === 'Fresno-Bakersfield' ||
			this.panelDashboardCtrl.selectedMarket[0] === 'Champaign-Peoria'
		) {
			this.showOverlay = true;
			this.showOverlayMsg = 'Low sample size.';
			this.emptyFields();
			return;
		}
		this.showOverlay = false;
	}

	emptyFields() {
		this.penetrationCYValue = '';
		this.buyingRateCYValue = '';
		this.frequencyCYValue = '';
		this.tripCYValue = '';
		this.buyingHouseholdsValue = '';
		this.penetrationChangeValue = '';
		this.buyingRateChangeValue = '';
		this.frequencyChangeValue = '';
		this.tripChangeValue = '';

		this.showRawOccasionsMessage = false;
		this.showRawOccasionsMessageExpandedView = false;
		this.shareOfWalmart = '';
		this.currentShareOfWalmartToTotalOutlets = '';
		this.shareOfWalmartIsRawBuyers = '';
		this.previousShareOfWalmartToTotalOutlets = '';
		this.panelDashboardCtrl.periodEndDate = '';
		this.top3Gained = '';
		this.top3Lost = '';

		d3.select('#shareOfWalmartPieChart').select('svg').remove();
		d3.select('#hiddenSVG').remove();

		this.completeListSow = [];
		this.totalOutletSow = '';
		d3.select('#channelsPieChart').select('svg').remove();
	}

	numberWithCommas(x) {
		return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
	}

	formatWithDollar(x) {
		if (x < 0) {
			return '-' + this.dollarExtension + this.numberWithCommas(x.substring(1));
		} else {
			return this.dollarExtension + this.numberWithCommas(x);
		}
	}

	getFontColorStyle(x) {
		if (parseFloat(x) > 0) {
			return 'font-green-value';
		} else if (parseFloat(x) < 0) {
			return 'font-red-value';
		} else {
			return '';
		}
	}

	checkIfShowRawOccasionsMessage() {
		if (
			this.panelDashboardCtrl.selectedLevelToShow === 'Retailer' &&
			this.shareOfWalmartIsRawBuyers
		) {
			return true;
		}

		for (let i = 0; i < this.top3Gained.length; i++) {
			if (this.top3Gained[i].rawOccasions) {
				return true;
			}
		}
		for (let j = 0; j < this.top3Lost.length; j++) {
			if (this.top3Lost[j].rawOccasions) {
				return true;
			}
		}

		return false;
	}

	appendInfoForTotalOutletSow() {
		if (this.totalOutletSow.buyingHouseholds !== 'RO') {
			this.totalOutletSow.buyingHouseholds = parseFloat(this.totalOutletSow.buyingHouseholds);
			this.totalOutletSow.buyingHouseholdsFormatted = this.numberWithCommas(
				this.totalOutletSow.buyingHouseholds
			);
		}
		if (this.totalOutletSow.dollarVolume !== 'RO') {
			this.totalOutletSow.dollarVolume = parseFloat(this.totalOutletSow.dollarVolume);
			this.totalOutletSow.dollarVolumeFormatted = this.formatWithDollar(
				this.totalOutletSow.dollarVolume
			);
		}
		if (this.totalOutletSow.wmShareWallet !== 'RO') {
			this.totalOutletSow.wmShareWalletFormatted = this.totalOutletSow.wmShareWallet;
			this.totalOutletSow.wmShareWallet = parseFloat(this.totalOutletSow.wmShareWallet);
		}
		if (this.totalOutletSow.value !== 'RO') {
			this.totalOutletSow.value = parseFloat(this.totalOutletSow.value);
			this.totalOutletSow.valueStyle = this.getFontColorStyle(this.totalOutletSow.value);
		}
	}

	appendInfoForCompleteListSow() {
		for (let i = 0; i < this.completeListSow.length; i++) {
			if (this.completeListSow[i].buyingHouseholds !== 'RO') {
				this.completeListSow[i].buyingHouseholds = parseFloat(
					this.completeListSow[i].buyingHouseholds
				);
				this.completeListSow[i].buyingHouseholdsFormatted = this.numberWithCommas(
					this.completeListSow[i].buyingHouseholds
				);
			}
			if (this.completeListSow[i].dollarVolume !== 'RO') {
				this.completeListSow[i].dollarVolume = parseFloat(this.completeListSow[i].dollarVolume);
				this.completeListSow[i].dollarVolumeFormatted = this.formatWithDollar(
					this.completeListSow[i].dollarVolume
				);
			}
			if (this.completeListSow[i].wmShareWallet !== 'RO') {
				this.completeListSow[i].wmShareWalletFormatted = this.completeListSow[i].wmShareWallet;
				this.completeListSow[i].wmShareWallet = parseFloat(this.completeListSow[i].wmShareWallet);
			}
			if (this.completeListSow[i].value !== 'RO') {
				this.completeListSow[i].value = parseFloat(this.completeListSow[i].value);
				this.completeListSow[i].valueStyle = this.getFontColorStyle(this.completeListSow[i].value);
			}
		}
		this.showRawOccasionsMessageExpandedView = this.checkIfShowRawOccasionsMessageExpandedView();
		this.sortBy(this.propertyNameOrdered, true);
	}

	/**
	 * Validation for guardrails (Raw Occasions) in expanded view
	 */
	checkIfShowRawOccasionsMessageExpandedView() {
		for (let i = 0; i < this.completeListSow.length; i++) {
			if (this.completeListSow[i].rawOccasions) {
				return true;
			}
		}
		return false;
	}

	sortBy(propertyNameSelected, flag?) {
		if (!flag) {
			this.reverse = this.propertyNameOrdered === propertyNameSelected ? !this.reverse : false;
		}
		this.propertyNameOrdered = propertyNameSelected;
		this.orderByField = propertyNameSelected;
		let tempOrder = this.reverse ? 'desc' : 'asc';
		let tempObjs = _.partition(this.completeListSow, obj => obj[this.propertyNameOrdered] === 'RO');
		let displayName = this.columnDefs[propertyNameSelected];
		this.expandableTable = _.orderBy(
			tempObjs[1],
			obj => parseFloat(obj[this.propertyNameOrdered]),
			[tempOrder]
		);
		this.completeListSow = this.expandableTable.concat(tempObjs[0]);
		this.product_latest_sort = { name: displayName, direction: tempOrder };
	}

	expandShareWallet() {
		this.shareOfWalletExpanded = !this.shareOfWalletExpanded;
	}
	ngOnDestroy(): void {
		this.unSubscribeAll.next();
		this.unSubscribeAll.complete();
	}
}
