import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { ROUTES_URL_TITLE } from 'src/app/config/route.config';
import { Location } from '@angular/common';
import { CONCIERGE_HOMEPAGE_TILES } from 'src/app/constants/dashboard-ui.constant';

@Injectable({
	providedIn: 'root'
})
export class TopbarUtilityService {
	title: any;
	conciergeTitle: any;
	constructor(private router: Router, private location: Location) {
		//Setting page title
		this.router.events.subscribe({
			next: event => {
				if (event instanceof NavigationEnd) {
					this.title = this.getRoutePageTitle(event.url);
					this.conciergeTitle = this.getConciergeRoutePageTitle(event.url);
				}
			}
		});
	}

	getRoutePageTitle(url: string) {
		return ROUTES_URL_TITLE[url];
	}

	getConciergeRoutePageTitle(url: string) {
		let pageHeading: any;
		CONCIERGE_HOMEPAGE_TILES.LANDING_PAGE.forEach(pageHeader => {
			pageHeader.subquestions.filter(link => {
				if (link.navlink === url) {
					pageHeading = pageHeader.heading + ' / ' + link.title;
				}
			});
		});
		return pageHeading;
	}

	getPageTitleReport() {
		return this.title;
	}

	getConciergePageTitle() {
		return this.conciergeTitle;
	}
}
