<div class="container-fluid m-0 p-0">
	<div class="row">
		<div class="col-12">
			<crf-ca-report-selector-header
				[questionId]="questionId"
				[defaultProductError]="defaultProductError"
				[selectorConfig]="headerComponentSelectorConfiguration"
				(selectorChange)="headerSelectorValueChangeHandler($event)"
				(export)="exportReport($event)"
			>
			</crf-ca-report-selector-header>
		</div>
	</div>
	<div class="row p-3">
		<div id="loadData" class="inner-second" *ngIf="isDataNOTAvailable">
			<div class="ovt-no-data-message">
				<span class="glyphicon glyphicon-exclamation-sign"></span>
				Data Not Available
				<span id="closeId" class="ovt-no-data-message-close" (click)="isDataNOTAvailable = false"
					>&times;</span
				>
			</div>
		</div>
		<div class="col-12">
			<p>
				Percentages represent the Dollar Volume Contribution to the Parent Characteristic for
				Walmart and Rest of Market.
			</p>
			<div id="cdtView" [hidden]="displayExpandedUpcTableView">
				<div class="tree-option-container d-flex justify-content-end align-items-center">
					<niq-toggle
						[variant]="'withLabel'"
						[size]="'small'"
						[checked]="false"
						[(ngModel)]="tree.expanded"
						[disabled]="false"
						[label]="'Expand'"
						(change)="toggleTreeExpand()"
					>
					</niq-toggle>
					<niq-slider
						[minValue]="50"
						[maxValue]="100"
						[value]="tree.zoom"
						[stepSize]="1"
						[showTicks]="false"
						(valueChangeEvent)="zoom($event)"
					></niq-slider>
				</div>
				<div id="tree-container" [style.zoom]="tree.zoom + '%'">
					<h4 class="tree-heading mb-5">Category: {{ values.category }}</h4>
					<div id="tree-table-container" class="mb-3">
						<table class="mt-4">
							<thead>
								<tr>
									<th id="categorycharacteristicranking" colspan="2" class="text-center">
										Category Characteristic Ranking
									</th>
								</tr>
								<tr>
									<th id="valuecategory" colspan="2" class="text-center">{{ values.category }}</th>
								</tr>
								<tr>
									<th id="characteristic" class="rt-bold-heading text-center">Characteristics</th>
									<th id="importanceranking" class="rt-bold-heading text-center">
										Importance Ranking
									</th>
								</tr>
							</thead>
							<tbody>
								<tr *ngFor="let ranking of tree.table.data.rankings">
									<td>{{ ranking.ch }}</td>
									<td>
										<div
											class="bar-chart-filler"
											[style.width]="
												tree.table.columnWidth(tree.table.data.rankings[0].corr, ranking.corr) + '%'
											"
										></div>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
					<div class="tree-body d-flex" style="overflow-x: auto">
						<div>
							<h4 class="tree-category-node py-3">{{ values.category }}</h4>
						</div>
						<crf-ca-consumer-decision-tree-node
							[node]="tree.data"
							[level]="0"
						></crf-ca-consumer-decision-tree-node>
					</div>
					<niq-spinner size="medium" [hidden]="tree.loaded"></niq-spinner>
				</div>
			</div>
			<crf-ca-consumer-decision-tree-upc-table
				[hidden]="!displayExpandedUpcTableView"
				(displayTreeViewEvent)="displayTreeView()"
			></crf-ca-consumer-decision-tree-upc-table>
		</div>
	</div>
	<div class="col-xs-12 footnote-block">
		<crf-ca-dashboard-footnote
			[reportDimension]="{
				dashboardName: 'Consumer Decision Tree',
				selectedMarket: ['Total US'],
				selectedLevelToShow: 'Subcategory',
				period: 'Latest 52 Weeks',
				periodEndDate: periodEnddate,
				selectedProduct: setSelectedProduct(selectedProducts)
			}"
		>
		</crf-ca-dashboard-footnote>
	</div>
</div>
