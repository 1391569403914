import {
	Component,
	EventEmitter,
	Input,
	OnDestroy,
	OnInit,
	Output,
	ViewChild,
	ElementRef
} from '@angular/core';
//import { ToastService, ToastState } from '@nielseniq/athena-core';
import { ReplaySubject, Subscription, forkJoin, map, takeUntil } from 'rxjs';
import {
	BY_LEVEL_REQUEST_TYPE,
	DISTRIBUTION,
	DOLLAR_CP,
	EQ,
	EQ_SM,
	PRODUCT_SELECTOR_MAPPING,
	UNITs_CP
} from 'src/app/constants/report.constant';
import { TOAST } from 'src/app/constants/toast.constant';
import { SaveDefaultComponent } from 'src/app/shared/components/selector/save-default/save-default.component';
import { ReportService } from 'src/app/service/report.service';
import { SELECTOR } from 'src/app/shared/constants/selector.constant';
import { ReportHeaderUtility } from '../../../service/report/report-header.utility';
import { FactSelectorComponent } from 'src/app/shared/components/selector/fact-selector/fact-selector.component';
import { ToastService } from '@nielseniq/athena-core';
import { SELECTOR_TYPE, SelectorConfig } from 'src/app/shared/model/report/report-header.type';
import { TopbarUtilityService } from 'src/app/core/service/topbar/topbar-utility.service';
import { UtilityService } from 'src/app/reports/services/utility.service';

@Component({
	selector: 'crf-ca-report-selector-header',
	templateUrl: './header.component.html',
	styleUrl: './header.component.scss'
})
export class HeaderComponent implements OnInit, OnDestroy {
	@ViewChild(SaveDefaultComponent) saveDefaultMenu: SaveDefaultComponent;
	@ViewChild(FactSelectorComponent) factSelectorComponent: FactSelectorComponent;

	@Output() selectorChange = new EventEmitter<string>();
	@Output() factChange = new EventEmitter<void>();
	@Output() export = new EventEmitter<string>();

	@Input() questionId: number = -1;
	@Input() isEQFact: boolean = true;
	@Input() defaultProductError: boolean = false;
	@Input() isDistribution: boolean;
	@Input() selectorConfig: SelectorConfig[];

	selectors = {
		product: {
			value: {
				display: '',
				initial: {
					totalBusiness: [],
					majorBusiness: [],
					department: [],
					categoryGroup: [],
					category: [],
					subCategory: [],
					segment: [],
					brandOwnerHigh: [],
					brandOwnerLow: [],
					brandHigh: [],
					brandFamily: [],
					brandLow: [],
					brand: []
				},
				actual: {
					totalBusiness: [],
					majorBusiness: [],
					department: [],
					categoryGroup: [],
					category: [],
					subCategory: [],
					segment: [],
					brandOwnerHigh: [],
					brandOwnerLow: [],
					brandHigh: [],
					brandFamily: [],
					brandLow: [],
					brand: []
				}
			},
			ui: {
				display: false,
				disabled: false
			},
			config: {
				id: SELECTOR.PRODUCT.ID,
				multiSelect: false,
				apiFetched: false,
				available: false
			}
		},
		bylevel: {
			value: {
				display: '',
				initial: [],
				actual: [],
				dropdown: []
			},
			ui: {
				display: false,
				disabled: false,
				loaded: false
			},
			config: {
				id: SELECTOR.BYLEVEL.ID,
				multiSelect: false,
				apiFetched: false,
				available: false
			}
		},
		market: {
			value: {
				display: '',
				initial: [],
				actual: [],
				dropdown: []
			},
			ui: {
				display: false,
				disabled: false,
				loaded: false
			},
			config: {
				id: SELECTOR.MARKET.ID,
				multiSelect: false,
				apiFetched: false,
				available: false
			}
		},
		period: {
			value: {
				display: '',
				initial: [],
				actual: [],
				dropdown: []
			},
			ui: {
				display: false,
				disabled: false,
				loaded: false
			},
			config: {
				id: SELECTOR.PERIOD.ID,
				multiSelect: false,
				apiFetched: false,
				available: false
			}
		},
		fact: {
			value: {
				initial: {
					factType: [],
					facts: []
				},
				actual: {
					factType: [],
					facts: []
				},
				dropdown: []
			},
			ui: {
				display: false,
				disabled: false,
				loaded: false
			},
			config: {
				id: SELECTOR.FACT.ID,
				multiSelect: {
					header: false,
					facts: false
				},
				column: {
					others: false,
					eq: true
				},
				apiFetched: false,
				available: false,
				value: []
			}
		},
		periodenddate: {
			//Not displayed in UI, used in footer and api calls
			value: {
				actual: []
			},
			ui: {
				display: false
			}
		}
	};

	loadProgress$: ReplaySubject<string> = new ReplaySubject<string>();
	unsubscribeOnDestroy$: ReplaySubject<any> = new ReplaySubject<any>();
	pageTitle: any;
	selectedFactCount: number;
	isMarketMultiSelectEnable: boolean;

	constructor(
		private service: ReportService,
		private utility: ReportHeaderUtility,
		private toastService: ToastService,
		private topBarUtility: TopbarUtilityService,
		private utilityService: UtilityService,
		private elementRef: ElementRef
	) {}

	ngOnInit(): void {
		document.addEventListener('click', this.handleClickOutside.bind(this));
		this.pageTitle = this.topBarUtility.getPageTitleReport();
		//Load initial values
		console.log('Header: Start load initial values');
		//Load selector configuration to the selectors
		this.loadConfiguration(this.selectorConfig);
		this.init();
	}
	init(): void {
		this.service.getSelectors(this.questionId).subscribe({
			next: res => {
				if (res.responseData) this.setInitialValues(res.responseData);
				this.loadAllDropdown();
			}
		});
	}
	handleClickOutside(event: Event) {
		if (!this.elementRef.nativeElement.contains(event.target)) {
			// Clicked outside the dropdown, set display to false
			this.selectors.product.ui.display = false;
		}
	}
	onDropdownClick(event: Event) {
		event.stopPropagation();
	}
	loadAllDropdown() {
		//Loading Fact Selector
		if (this.selectors.fact.config.available) {
			if (this.selectors.fact.config.multiSelect.facts) {
				this.loadDropdown(SELECTOR.FACT.ID, [], true);
			} else {
				this.loadDropdown(SELECTOR.FACT.ID, [], true);
			}
			this.selectors.fact.ui.loaded = true;

			// Setting fact type based on initial load
			if (this.selectors.fact.value.actual.factType.length === 0) {
				if (
					!this.selectors.fact.config.multiSelect.header &&
					this.selectors.fact.value.initial.facts.length > 0
				) {
					if (this.selectors.fact.value.initial.facts[0].includes('Unit')) {
						this.selectors.fact.value.actual.factType.push('UNITS');
					} else if (this.selectors.fact.value.initial.facts[0].includes('EQ')) {
						this.selectors.fact.value.actual.factType.push('EQ');
					} else {
						this.selectors.fact.value.actual.factType.push('DOLLAR');
					}
				} else {
					// Default to DOLLAR if no specific fact type is determined
					this.selectors.fact.value.actual.factType.push('DOLLAR');
				}
			}
		}

		forkJoin([
			this.service.getByLevel(
				this.questionId,
				this.utility.getByLevelRequestType(this.selectors.product.value.initial),
				this.utility.getByLevelSelectorRequestParam(this.selectors.product.value.initial)
			),
			this.service.getMarket(),
			this.service.getPeriod(),
			this.service.getPeriodEndDate(this.questionId, 13, this.utility.getPeriodEndDatePayload())
		])
			.pipe(takeUntil(this.unsubscribeOnDestroy$))
			.pipe(
				map(([byLevel, markets, periods, periodEndDate]) => {
					return {
						byLevel: byLevel,
						markets: markets,
						periods: periods,
						periodEndDate: periodEndDate
					};
				})
			)
			.subscribe({
				next: res => {
					if (res.byLevel?.result)
						this.loadDropdown(
							SELECTOR.BYLEVEL.ID,
							res.byLevel?.result,
							true,
							this.selectors.bylevel.value.initial
						);

					//Load Market
					if (res.markets?.responseData)
						this.loadDropdown(
							SELECTOR.MARKET.ID,
							res.markets?.responseData,
							true,
							this.selectors.market.value.initial
						);

					//Load Period
					if (res.periods?.responseData)
						this.loadDropdown(SELECTOR.PERIOD.ID, res.periods?.responseData, true);

					//Loading Period End Date
					if (res.periodEndDate?.periodEndDate)
						this.selectors.periodenddate.value.actual = [res.periodEndDate?.periodEndDate];

					this.loadProgress$.complete();
				}
			});
	}

	//LOAD CONFIGUATION BASED ON THE REPORT
	loadConfiguration(selectorConfigs: SelectorConfig[]): void {
		console.log('Load configuration called');
		//Mapping config to the UI and preparing async list to track loading completion
		let asyncList: string[] = ['init'];
		selectorConfigs.forEach(selectorConfig => {
			if (selectorConfig.apiFetched && selectorConfig.id != SELECTOR.PRODUCT.ID)
				asyncList.push(selectorConfig.id);
			this.selectors[selectorConfig.id].config = selectorConfig;
		});

		//Triggering complete call for parent component to load report
		let subscription: Subscription = this.loadProgress$.subscribe({
			next: res => {
				if (asyncList.includes(res)) asyncList.splice(asyncList.indexOf(res), 1);
				if (asyncList.length == 0) this.loadProgress$.complete();
			},
			error: err => subscription?.unsubscribe(),
			complete: () => subscription?.unsubscribe()
		});
	}

	//Initial values
	setInitialValues(data): void {
		if (data) {
			//Product
			Object.keys(this.selectors.product.value.initial).forEach(productKey => {
				if (data[productKey.toLowerCase()])
					this.selectors.product.value.initial[productKey] =
						data[productKey.toLowerCase()].split(';');
			});
			this.selectors.product.value.actual = this.selectors.product.value.initial;
			this.updateDisplayValue(SELECTOR.PRODUCT.ID);

			//By Level
			if (data.bylevel) this.selectors.bylevel.value.initial = data.bylevel.split(';');

			//Market
			if (data.market) this.selectors.market.value.initial = data.market.split(';');

			//Period
			if (data.periodIds) this.selectors.period.value.initial = data.periodIds.split(';');

			//Fact
			Object.keys(this.selectors.fact.value.initial).forEach(key => {
				if (this.selectors.fact.value.initial[key] && data[key])
					this.selectors.fact.value.initial[key] = data[key].split(';');
			});
		}
	}

	//UPDATE DISPLAY VALUE
	updateDisplayValue(selector: SELECTOR_TYPE): void {
		let values: any = this.selectors[selector].value.actual;

		if (values)
			switch (selector) {
				case SELECTOR.PRODUCT.ID:
					Object.keys(values).forEach(productK => {
						if (values[productK]?.length > 0)
							this.selectors.product.value.display = values[productK][0];
					});
					break;
				case SELECTOR.BYLEVEL.ID:
				case SELECTOR.PERIOD.ID:
					if (values.length > 0)
						this.selectors[selector.toString()].value.display = values[0].value;
					break;
				case SELECTOR.MARKET.ID:
					if (values.length > 0) this.selectors[selector.toString()].value.display = values[0].key;
					break;
			}
		else this.toast('warning', 'Report Header', 'Update Display Value: Invalid values');
	}

	//CLEAR SELECTOR VALUES (ACTUAL AND DISPLAY)
	clearValues(selectorKey: SELECTOR_TYPE): void {
		console.log('Clearing value of ' + selectorKey);
		switch (selectorKey) {
			case SELECTOR.PRODUCT.ID:
				Object.keys(this.selectors.product.value.initial).forEach(productK => {
					this.selectors.product.value.actual[productK] = [];
				});
				this.selectors.product.value.display = '';
				break;
			case SELECTOR.BYLEVEL.ID:
			case SELECTOR.PERIOD.ID:
			case SELECTOR.MARKET.ID:
				this.selectors[selectorKey].value.actual = [];
				this.selectors[selectorKey.toString()].value.display = '';
				break;
			case SELECTOR.FACT.ID:
				this.selectors.fact.value.actual.facts = [];
				break;
		}
	}

	//LOAD DROPDOWN
	loadDropdown(
		selectorKey: SELECTOR_TYPE,
		values: any,
		initial: boolean,
		...checkedValues: any
	): void {
		if (values) {
			this.clearValues(selectorKey);
			this.selectors[selectorKey.toString()].value.dropdown = [];
			switch (selectorKey) {
				case SELECTOR.BYLEVEL.ID:
					this.loadByLevelDropdown(values, initial);
					break;
				case SELECTOR.PERIOD.ID:
				case SELECTOR.MARKET.ID:
					let defaultValue = null;
					Object.keys(values).forEach(itemKey => {
						let dropdownItem = {
							key: itemKey,
							value: selectorKey === 'market' ? values[itemKey] : itemKey,
							isSelected: false,
							api: { key: itemKey, value: values[itemKey] }
						};

						if (checkedValues)
							dropdownItem.isSelected =
								(checkedValues.length > 0 && checkedValues[0].length) > 0
									? checkedValues[0].indexOf(itemKey) != -1
									: false;

						this.selectors[selectorKey.toString()].value.dropdown.push(dropdownItem);

						if (initial && this.selectors[selectorKey.toString()].value.initial.includes(itemKey)) {
							this.selectors[selectorKey.toString()].value.actual.push(dropdownItem);
						}

						if (SELECTOR.PERIOD.ID === selectorKey && values[itemKey].defaultPeriod)
							defaultValue = dropdownItem;
						else if (SELECTOR.MARKET.ID === selectorKey && itemKey === 'Total US')
							defaultValue = dropdownItem;
					});

					// Call Distribution method
					if (SELECTOR.MARKET.ID === selectorKey && this.selectors.market.value.actual.length > 1) {
						this.enableOrDisableDistributionFacts();
					}
					if (this.selectors[selectorKey.toString()].value.actual.length === 0 && defaultValue)
						this.selectors[selectorKey.toString()].value.actual.push(defaultValue);

					if (SELECTOR.PERIOD.ID === selectorKey && this.selectors.period.value.actual.length > 0)
						this.utilityService.preselectDefault(
							this.selectors.period.value.dropdown,
							this.selectors.period.value.actual[0].key
						);

					this.selectors[selectorKey.toString()].ui.loaded = true;

					if (this.selectors.period.value.actual.length > 0)
						this.utilityService.preselectDefault(
							this.selectors.period.value.dropdown,
							this.selectors.period.value.actual[0].key
						);
					break;
				case SELECTOR.FACT.ID:
					if (this.selectors.fact.config.multiSelect.facts) this.loadFactDropdown(initial);
					else if (!this.selectors.fact.config.multiSelect.facts)
						this.loadSingleFactDropdown(initial);
					break;
			}

			this.updateDisplayValue(selectorKey);
		} else this.toast('warning', 'Report Header', 'Load Dropdown: Invalid values');
	}

	valueChangeHandler(event: any, selectorKey: SELECTOR_TYPE): void {
		if (event) {
			this.clearValues(selectorKey);
			switch (selectorKey) {
				case SELECTOR.PRODUCT.ID:
					this.productValueChangeHandler(event);
					break;
				case SELECTOR.BYLEVEL.ID:
				case SELECTOR.PERIOD.ID:
				case SELECTOR.MARKET.ID:
					if (!this.selectors[selectorKey.toString()].config.multiSelect) {
						this.selectors[selectorKey.toString()].value.actual.push(event);
					} else {
						this.selectors[selectorKey.toString()].value.actual = event;
						this.enableOrDisableDistributionFacts();
					}
					break;
				case SELECTOR.FACT.ID:
					if (
						this.selectors.fact.config.multiSelect.facts &&
						this.selectors.fact.config.multiSelect.header
					) {
						// Update for multiple selects with header
						this.selectors.fact.value.actual.facts = event.filter(
							fact =>
								fact.isCheckedDollar ||
								fact.isCheckedUnit ||
								fact.isCheckedOther ||
								fact.isCheckedDistribution ||
								fact.isCheckedEQ
						);
					} else if (
						this.selectors.fact.config.multiSelect.facts &&
						!this.selectors.fact.config.multiSelect.header
					) {
						// Update for multiple selects without header
						this.selectors.fact.value.actual.factType = [event.header];
						this.selectors.fact.value.actual.facts = event.fact.filter(
							fact =>
								fact.isCheckedDollar ||
								fact.isCheckedUnit ||
								fact.isCheckedOther ||
								fact.isCheckedEQ ||
								fact.isCheckedDistribution
						);
						if (this.isDistribution) {
							// Update market to single select if any of distribution fact is selected
							this.isMarketMultiSelectEnable = !this.selectors.fact.value.actual.facts.some(
								col => col.valueBase === DISTRIBUTION
							);
						}
					} else {
						// Update for single sele
						this.selectors.fact.value.actual.facts = this.utility
							.getFactMapSingle(this.selectors.fact.config.value)
							.get(event.value.key);

						this.selectors.fact.value.actual.factType = [event.header];
						this.isMarketMultiSelectEnable = true;
					}
					//Get selected Fact count
					this.getSelectedFactCount(event, false);
					break;
			}
			this.updateDisplayValue(selectorKey);

			// Hiding the dropdown popup
			this.selectors[selectorKey].ui.display = false;
			this.selectorChange.emit(selectorKey);
		}
	}

	//HELPER METHODS
	//LOAD DROPDOWN ITEMS
	loadByLevelDropdown(values: any, initial: boolean) {
		let byLevelDefaultValue = null;

		let currentLevel = '';
		Object.keys(this.selectors.product.value.actual).forEach(productKey => {
			if (this.selectors.product.value.actual[productKey].length > 0)
				currentLevel = BY_LEVEL_REQUEST_TYPE[productKey];
		});

		let defaultIndex = values.findIndex(e => e.key === currentLevel);

		defaultIndex =
			defaultIndex === -1
				? 0
				: defaultIndex === values.length - 1
				? values.length - 1
				: defaultIndex + 1;

		values.forEach((item, index) => {
			let dropdownItem = {
				key: item.key,
				value: item.value,
				api: { key: item.key, value: item }
			};
			this.selectors.bylevel.value.dropdown.push(dropdownItem);

			if (initial && this.selectors.bylevel.value.initial.includes(dropdownItem.key))
				this.selectors.bylevel.value.actual.push(dropdownItem);

			if (index === defaultIndex) byLevelDefaultValue = dropdownItem;
		});

		if (this.selectors.bylevel.value.actual.length === 0 && byLevelDefaultValue)
			this.selectors.bylevel.value.actual.push(byLevelDefaultValue);

		this.utilityService.preselectDefault(
			this.selectors.bylevel.value.dropdown,
			this.selectors.bylevel.value.actual[0].value
		);
	}

	loadFactDropdown(initial: boolean) {
		let factDataMap: Map<String, any[]> = this.utility.getFactMap(this.selectors.fact.config.value);

		// Removing product as it will not be displayed in the fact dropdown
		factDataMap.delete('Products');
		factDataMap.delete('Product Description');

		// Formatting data for passing it to Fact dropdown component
		factDataMap.forEach(fact => {
			let keyname = this.utility.getFactSelectorMapping(fact);

			// If initial check the value
			if (initial) {
				if (this.selectors.fact.value.initial.facts.indexOf(keyname.nameDollars) > -1)
					keyname.isCheckedDollar = true;
				if (this.selectors.fact.value.initial.facts.indexOf(keyname.nameUnits) > -1)
					keyname.isCheckedUnit = true;
				if (this.selectors.fact.value.initial.facts.indexOf(keyname.nameOthers) > -1)
					keyname.isCheckedOther = true;
				if (this.selectors.fact.value.initial.facts.indexOf(keyname.nameEQ) > -1)
					keyname.isCheckedEQ = true;
				if (this.selectors.fact.value.initial.facts.indexOf(keyname.nameDistribution) > -1)
					if (
						this.selectors.market.value.actual.length > 1 ||
						(this.selectors.market.value.initial.length > 1 && this.isDistribution)
					) {
						// Unselected all distribution if user has multimarket as default
						// Handled scenario :In prod user might have some default which we move to distribution fact
						keyname.isCheckedDistribution = false;
					} else {
						keyname.isCheckedDistribution = true;
					}
			}

			// Adding value to dropdown
			this.selectors.fact.value.dropdown.push(keyname);

			// Adding value to actual value if its checked
			if (
				keyname.isCheckedDollar ||
				keyname.isCheckedUnit ||
				keyname.isCheckedOther ||
				keyname.isCheckedEQ ||
				keyname.isCheckedDistribution
			)
				this.selectors.fact.value.actual.facts.push(keyname);
		});
		if (this.isDistribution) {
			// Handled default case
			this.isMarketMultiSelectEnable = !this.selectors.fact.value.actual.facts.some(
				col => col.valueBase === DISTRIBUTION
			);
		}

		//Get actual count of fact selected
		this.getSelectedFactCount(this.selectors.fact.value.actual.facts, false);
	}

	loadSingleFactDropdown(initial: boolean) {
		let factDataMap: Map<String, any[]> = this.utility.getFactMapSingle(
			this.selectors.fact.config.value
		);

		// Setting fact type as DOLLAR if factType is null in the initial load
		if (this.selectors.fact.value.initial.facts.length > 0 && initial) {
			if (this.selectors.fact.value.initial.facts[0].includes('Unit'))
				// Setting fact type
				this.selectors.fact.value.actual.factType = ['UNITS'];
			else if (this.selectors.fact.value.initial.facts[0].includes('EQ'))
				// Setting fact type
				this.selectors.fact.value.actual.factType = ['EQ'];
			else this.selectors.fact.value.actual.factType = ['DOLLAR'];
		} else {
			this.selectors.fact.value.actual.factType = ['DOLLAR'];
		}

		factDataMap.forEach(fact => {
			// Adding value to dropdown
			let dropdownItem = {
				key: fact[0].factGroup,
				isDollarChecked: false,
				isUnitChecked: false,
				isEQChecked: false,
				dollarKey: '',
				unitKey: '',
				eqKey: ''
			};

			// Setting dollarkey, unit key, and eq key for save as default
			for (let i = 0; i < fact.length; i++) {
				if (fact[i].valuebase === 'dollar' && dropdownItem.dollarKey === '')
					dropdownItem.dollarKey = fact[i].gkey;
				else if (fact[i].valuebase === 'units' && dropdownItem.unitKey === '')
					dropdownItem.unitKey = fact[i].gkey;
				else if (fact[i].valuebase === EQ_SM && dropdownItem.eqKey === '') {
					dropdownItem.eqKey = fact[i].gkey;
				}
			}

			if (initial && this.selectors.fact.value.initial.facts.length > 0) {
				for (let i = 0; i < fact.length; i++) {
					if (fact[i].gkey === this.selectors.fact.value.initial.facts[0]) {
						this.selectors.fact.value.actual.facts = fact;
						if (this.selectors.fact.value.actual.factType[0] === UNITs_CP) {
							dropdownItem.isUnitChecked = true;
						} else if (this.selectors.fact.value.actual.factType[0] === EQ) {
							dropdownItem.isEQChecked = true;
						} else if (this.selectors.fact.value.actual.factType[0] === DOLLAR_CP) {
							dropdownItem.isDollarChecked = true;
						} else {
							dropdownItem.isDollarChecked = true;
						}
						break;
					}
				}
			}
			this.selectors.fact.value.dropdown.push(dropdownItem);
			this.isMarketMultiSelectEnable = true;
		});

		// Setting initial values if initial value is null
		if (this.selectors.fact.value.actual.facts.length == 0) {
			// Set value if no default is present
			this.selectors.fact.value.actual.facts = factDataMap.values().next().value;
		}
	}

	//SETTING VALUES AND DROPDOWN

	//LOADING SELECTOR
	//Product Selector (Handled at the child component level)
	//By Level Selector
	loadByLevelSelector(questionId: number, type: string, params: any, initial: boolean): void {
		console.log('Header: Start load by level selector');

		let subscription: Subscription = this.service.getByLevel(questionId, type, params)?.subscribe({
			next: res => {
				if (res.result)
					this.loadDropdown(
						SELECTOR.BYLEVEL.ID,
						res.result,
						initial,
						this.selectors.bylevel.value.initial
					);
				else this.toast('warning', 'Report Header', 'Load By Level Selector: Invalid Reponse');
			},
			error: err => {
				this.loadProgress$.next(SELECTOR.BYLEVEL.ID);
				this.toast('error', 'Report Header', 'Load By Level Selector: Invalid Reponse');
				subscription?.unsubscribe();
			},
			complete: () => {
				this.loadProgress$.next(SELECTOR.BYLEVEL.ID);
				subscription?.unsubscribe();
			}
		});
	}

	//Value Change Handler
	//Product
	productValueChangeHandler(eventValue: any): void {
		eventValue = this.selectors.product.config.multiSelect ? eventValue : eventValue.optedValues;
		if (eventValue) {
			this.clearValues(SELECTOR.PRODUCT.ID);
			//Updating actual value
			eventValue.forEach(element => {
				let key = this.selectors.product.config.multiSelect
					? PRODUCT_SELECTOR_MAPPING[element.key].PAYLOAD_KEY
					: element.payloadField;

				if (element.value) this.selectors.product.value.actual[key]?.push(element.value);
			});

			this.updateDisplayValue(SELECTOR.PRODUCT.ID);

			//Loading the By Level Selector if the product selector changes
			if (this.selectors.bylevel.config.available) {
				let bylevelRequestType: string = this.utility.getByLevelRequestType(
					this.selectors.product.value.actual
				);
				let bylevelRequestParams: any = this.utility.getByLevelSelectorRequestParam(
					this.selectors.product.value.actual
				);
				this.loadByLevelSelector(this.questionId, bylevelRequestType, bylevelRequestParams, false);
			}
		}
	}

	saveResetDefault(event: any, type: string) {
		if (event.menuId == 1) {
			//Save defaults
			switch (type) {
				case 'ALL':
					let payload = this.utility.getSaveSelectionPayload(this.getSelectorValues());

					if (
						!this.selectors.fact.config.multiSelect.facts &&
						!this.selectors.fact.config.multiSelect.facts
					) {
						let fact = this.selectors.fact.value.actual.facts;
						let factType = this.selectors.fact.value.actual.factType[0];
						for (let i = 0; i < fact.length; i++) {
							if (factType === 'DOLLAR' && fact[i].valuebase === 'dollar') {
								payload.facts = fact[i].gkey;
								break;
							} else if (factType === 'UNITS' && fact[i].valuebase === 'units') {
								payload.facts = fact[i].gkey;
								break;
							} else if (factType === 'EQ' && fact[i].valuebase === 'eq') {
								payload.facts = fact[i].gkey;
								break;
							}
						}
					}

					let subscription: Subscription = this.service
						.saveSelectors(this.questionId, 'all', payload)
						.subscribe({
							next: res => this.toast('success', 'Success', 'Selections saved successfully'),
							error: err => {
								this.toast('error', 'Failed', 'Invalid Response');
								subscription?.unsubscribe();
							},
							complete: () => {
								this.loadProgress$.next('init');
								subscription?.unsubscribe();
							}
						});
					break;
			}
		} else if (event.menuId == 2) {
			this.clearValues(SELECTOR.PRODUCT.ID);
			this.getSelectedFactCount(this.selectors.fact.value.actual.facts, false);
			let subscription: Subscription = this.service.getSelectors(this.questionId)?.subscribe({
				next: res => {
					if (res.responseData) {
						this.setInitialValues(res.responseData);
						this.loadAllDropdown();
					} else this.toast('warning', 'Report Header', 'Save Reset Default: Invalid Response');
					this.selectorChange.emit(SELECTOR.PRODUCT.ID);
				},
				error: err => {
					this.loadProgress$.next('init');
					this.toast('error', 'Report Header', 'Load Initial Values: Invalid Response');
					subscription?.unsubscribe();
				},
				complete: () => {
					this.loadProgress$.next('init');
					subscription?.unsubscribe();
				}
			});
		}
	}

	getSelectorValues(): any {
		let values = {};
		Object.keys(this.selectors).forEach(selectorKey => {
			if (this.selectors[selectorKey]?.config?.available || selectorKey == 'periodenddate')
				values[selectorKey] = this.selectors[selectorKey]?.value.actual;
		});
		return values;
	}

	getDropdownItems(selector: SELECTOR_TYPE): any {
		let dropdown = [];

		switch (selector) {
			case 'market':
				dropdown = this.selectors.market.value.dropdown;
				break;
			case 'bylevel':
				dropdown = this.selectors.bylevel.value.dropdown;
				break;
			case 'period':
				dropdown = this.selectors.period.value.dropdown;
				break;
		}

		return dropdown;
	}

	//UI utility
	toast(type: any, title: string, message: string): void {
		this.toastService.InjectToast(type, message, '', TOAST.TIMEOUT, '', title, TOAST.SIZE);
	}

	toggleDropdown(selectorId: string): void {
		Object.keys(this.selectors).forEach(selectorKey => {
			if (selectorKey !== selectorId) this.selectors[selectorKey].ui.display = false;
			else this.selectors[selectorKey].ui.display = !this.selectors[selectorKey].ui.display;
		});
		this.saveDefaultMenu.showMoreOptions = false;
	}

	closeAllDropdown(): void {
		Object.keys(this.selectors).forEach(
			selectorKey => (this.selectors[selectorKey].ui.display = false)
		);
	}

	disableFilter(selectorKey: string): void {
		this.selectors[selectorKey].ui.disabled = true;
	}

	enableFilter(selectorKey: string): void {
		this.selectors[selectorKey].ui.disabled = false;
	}

	getSelectorCount(selectorKey): number {
		let count = 0;

		switch (selectorKey) {
			case this.selectors.product.config.id:
				Object.keys(this.selectors[selectorKey].value.actual).forEach(productKey => {
					if (this.selectors[selectorKey].value.actual[productKey].length > 0)
						count = this.selectors[selectorKey].value.actual[productKey].length;
				});
				break;
			case this.selectors.market.config.id:
				count = this.selectors.market.value.actual.length;
		}

		return count;
	}

	getLoadProgress() {
		return this.loadProgress$;
	}

	exportReport(type: string) {
		this.export.emit(type);
	}

	ngOnDestroy(): void {
		this.unsubscribeOnDestroy$.next(true);
		this.unsubscribeOnDestroy$.complete();
		document.removeEventListener('click', this.handleClickOutside.bind(this));
	}

	enableOrDisableDistributionFacts() {
		if (this.isDistribution) {
			let selectedMarketsLength = this.selectors.market.value.actual.length > 1;
			this.selectors.fact.value.dropdown.forEach(fact => {
				if (fact.valueBase === DISTRIBUTION && selectedMarketsLength) {
					// Set true or false based on selected market is more than one
					fact.isDistributionPinned = true;
					fact.isCheckedDistribution = false;
				}
				if (fact.valueBase === DISTRIBUTION && !selectedMarketsLength) {
					fact.isDistributionPinned = false;
				}
			});
		}
	}

	getSelectedFactCount(factData, reset) {
		this.selectedFactCount = 0;
		if (
			(this.selectors.fact.config.multiSelect.facts &&
				this.selectors.fact.config.multiSelect.header) ||
			reset
		) {
			factData.forEach(factData => {
				if (factData.isCheckedDollar) this.selectedFactCount++;
				if (factData.isCheckedUnit) this.selectedFactCount++;
				if (factData.isCheckedOther) this.selectedFactCount++;
				if (factData.isCheckedEQ) this.selectedFactCount++;
			});
		} else if (
			this.selectors.fact.config.multiSelect.facts &&
			!this.selectors.fact.config.multiSelect.header
		) {
			this.selectedFactCount = this.selectors.fact.value.actual.facts.length;
		}
	}
}
