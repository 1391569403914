<div class="overlay-bg-ppt" *ngIf="enableLoader">
	<span class="spinner-large">
		<niq-spinner colorTheme="light" size="medium" variant="brand"></niq-spinner>
	</span>
</div>
<div class="row">
	<div class="col col-lg-12 col-md-12 col-sm-12 col-xs-12">
		<div id="loadData" *ngIf="!chartData && chartData.length === 0">
			<div id="overlay" class="ovt-general-overlay"></div>
			<div class="ovt-no-data-message">
				<span class="glyphicon glyphicon-exclamation-sign"></span>
				No Data Available
				<span
					id="closeId"
					class="ovt-no-data-message-close"
					ng-click="shareCtrl.showOverlay = false"
					>&times;</span
				>
			</div>
		</div>
		<div class="row">
			<div class="col col-lg-2 col-md-2 col-sm-3 col-xs-4 fact-selection">
				<div class="dropdown dropdown-ovt">
					<a
						id="baseFactSelectBtn"
						type="button"
						data-toggle="dropdown"
						aria-haspopup="true"
						aria-expanded="false"
						(click)="showUnitMenu()"
					>
						<div class="row">
							<span class="text col-lg-10">{{ measureSelected.value }}</span>
							<span class="arrow col-lg-2"
								><niq-icon state="primary" size="small" iconName="chevron-down"></niq-icon
							></span>
						</div>
					</a>
					<ul class="dropdown-menu dropdown-list" [hidden]="!showUnitDropdown">
						<li *ngFor="let measure of measures">
							<a (click)="updateMeasureSelection(measure)">{{ measure.value }}</a>
						</li>
					</ul>
				</div>
			</div>
			<div class="col col-lg-3 col-md-3 col-sm-3 col-xs-3 period-comparison-fact-prompt">
				<div class="dropdown dropdown-ovt">
					<a
						id="baseFactSelectBtn"
						type="button"
						data-toggle="dropdown"
						aria-haspopup="true"
						aria-expanded="false"
						(click)="showFactMenu()"
					>
						<div class="row">
							<span class="text col-lg-10">{{ factSelected.value }}</span>
							<span class="arrow col-lg-2"
								><niq-icon state="primary" size="small" iconName="chevron-down"></niq-icon
							></span>
						</div>
					</a>
					<ul class="dropdown-menu dropdown-list" [hidden]="!showFactDropdown">
						<li *ngFor="let fact of factLocalPromptValues[measureSelected.key]">
							<a (click)="updateFactSelection(fact)">{{ fact.value }}</a>
						</li>
					</ul>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col col-lg-12 col-md-12 col-sm-12 col-xs-12">
				<div
					#containerElement
					class="shared-report-chart-dashboard"
					ng-if="shareCtrl.enableBarChart"
				>
					<div class="row">
						<div>
							<span id="wovtBarDotChartLeft" style="float: left; margin-left: 10px"></span>
							<span id="wovtBarDotChartParentCenter"></span>
							<div
								id="wovtBarDotChartCenterScrollDiv"
								style="display: inline-block"
								style="overflow-x: auto; width: auto"
							>
								<div id="wovtBarDotChartOtherCenter" style="display: block"></div>
								<div
									id="wovtBarDotChartOtherCenterLabels"
									style="display: block"
									[hidden]="!showChildLabelText"
								>
									<div
										id="wmovtxAxisLabel"
										class="wmovtxAxisLabelText"
										*ngFor="let product of barDataList"
										[style.width.px]="labelWidth"
									>
										{{ product.productName }}
									</div>
								</div>
							</div>

							<span id="wovtBarDotChartRight" style="float: right"></span>
						</div>
					</div>
					<div class="row">
						<span><span id="wovtBarDotChartLegend"></span></span>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
