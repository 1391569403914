import {
	Component,
	ElementRef,
	EventEmitter,
	Input,
	OnInit,
	Output,
	ViewChild
} from '@angular/core';
import { FACTLOCALPROMPTVALUES, MEASURES } from 'src/app/models/share-prompt.constant';
import { MeasuresUnit } from 'src/app/models/report-dimension.model';
import * as d3 from 'd3';
import { ShareReportDashboardService } from 'src/app/reports/services/share-report-dashboard.service';
import { SHARE_REPORT_CONSTANTS } from 'src/app/reports/share-report/constants/share-report-constants';
import * as _ from 'lodash';

@Component({
	selector: 'crf-ca-period-comparison',
	templateUrl: './period-comparison.component.html',
	styleUrl: './period-comparison.component.scss'
})
export class PeriodComparisonComponent implements OnInit {
	@Input() selectedPeriod: Array<any>;
	@Input() chartData: Array<any>;
	@Input() selectedUnits: any;
	@Input() selectedFactGraph: any;
	@ViewChild('containerElement', { static: true }) containerElement: ElementRef;
	@Output() sortDetails = new EventEmitter<any>();
	@Output() selectedMeasure = new EventEmitter<any>();
	@Output() factSelectedData = new EventEmitter<any>();
	showUnitDropdown: boolean = false;
	showFactDropdown: boolean = false;
	private data = [
		{ group: 'Group 1', value1: 10, value2: 20, value3: 31 },
		{ group: 'Group 2', value1: 15, value2: 25, value3: 32 },
		{ group: 'Group 3', value1: 20, value2: 30, value3: 33 },
		{ group: 'Group 4', value1: 25, value2: 35, value3: 35 }
	];

	private svg: any;
	private margin = 50;
	private width = 750 - this.margin * 2;
	private height = 400 - this.margin * 2;

	/* Bar chart Declaration */
	clearChartFlag: boolean;
	enableBarChart: boolean;
	measureSelected: any = {};
	measures: MeasuresUnit[] = MEASURES;
	factSelected: any = {};
	period_tab_latest_sort: any = '';
	factLocalPromptValues: any = FACTLOCALPROMPTVALUES;
	g: any;
	filteredLegendsToShow: any[];
	dashboardName: string = 'Share Report';

	barChartKeys = [];
	dotChartKeys = [];
	dataArray: any = {};
	leftYAxisName = '';
	rightYAxisName = '';
	yMinLeft: number;
	yMaxLeft: number;
	yMinRight: number;
	yMaxRight: number;
	barDataList: any;

	BAR_DOT_CHART_SVG1 = '#wovtBarDotChartLeftSvg';
	BAR_DOT_CHART_SVG2 = '#wovtBarDotChartParentCenterSvg';
	BAR_DOT_CHART_SVG3 = '#wovtBarDotChartOtherCenterSvg';
	BAR_DOT_CHART_SVG4 = '#wovtBarDotChartRightSvg';
	LEGENDS_SVG = '#wovtBarDotChartLegendSvg';
	TEXT_ANCHOR = 'text-anchor';
	INLINE_BLOCK = 'inline-block';
	MAX_HEIGHT_STR = 'max-height';
	STROKE_WIDTH_STR: string = 'stroke-width';
	TICK_TEXT_STR = '.tick text';

	parentBarDataSet = [];
	parentDotDataSet = [];
	isParentBarNeeded: boolean;
	showChildLabelText: boolean;
	labelWidth: number;
	enableLoader: boolean;

	constructor(public shareReportDashboardService: ShareReportDashboardService) {}

	ngOnInit(): void {
		this.loadChartData();
		this.resetChartData();
		this.setLegendsToShow();
		this.generateWovtBarDotChartData();
		this.disableLoaderEvent();
	}

	showUnitMenu() {
		this.showUnitDropdown = !this.showUnitDropdown;
	}

	showFactMenu() {
		this.showFactDropdown = !this.showFactDropdown;
	}

	disableLoaderEvent() {
		this.disableloader();
	}

	resetChartData() {
		d3.select(this.BAR_DOT_CHART_SVG1).remove();
		d3.select(this.BAR_DOT_CHART_SVG2).remove();
		d3.select(this.BAR_DOT_CHART_SVG3).remove();
		d3.select(this.BAR_DOT_CHART_SVG4).remove();
		d3.select(this.LEGENDS_SVG).remove();
		this.dataArray = {};
		this.barChartKeys = [];
		this.dotChartKeys = [];
		this.leftYAxisName = '';
		this.rightYAxisName = '';
		this.showChildLabelText = false;
		this.barDataList = [];
		this.parentDotDataSet = [];
	}

	disableloader() {
		this.enableLoader = false;
	}

	setLegendsToShow() {
		this.filteredLegendsToShow = [];
		const periods = this.selectedPeriod;
		if (periods) {
			periods.forEach(data => {
				if (data.isSelected) {
					this.filteredLegendsToShow.push(data);
				}
			});
		}
	}

	generateWovtBarDotChartData() {
		if (
			this.chartData &&
			this.chartData.length > 0 &&
			this.dashboardName &&
			this.filteredLegendsToShow &&
			this.filteredLegendsToShow.length > 0
		) {
			if (this.dashboardName === 'Share Report') {
				this.drawShareReportChart();
			}
		} else {
			this.disableloader();
		}
	}

	drawShareReportChart() {
		//Get data for array
		this.dataArray = this.shareReportDashboardService.getShareReportDataForChart(
			this.filteredLegendsToShow,
			this.chartData,
			this.measureSelected,
			this.factSelected
		);
		if (this.dataArray && this.dataArray.barDatasetList && this.dataArray.dotDataSetList) {
			this.barChartKeys = this.dataArray.barChartKeys;
			this.dotChartKeys = this.dataArray.dotChartKeys;

			if (
				this.dataArray.barDatasetList.length > 0 &&
				this.dataArray.dotDataSetList.length > 0 &&
				this.dataArray.enclosingDivContainerID &&
				this.dataArray.enclosingDivContainerID !== ''
			) {
				this.leftYAxisName = this.dataArray.leftYAxisName;
				this.rightYAxisName = this.dataArray.rightYAxisName;
				this.drawGraph(
					this.dataArray.barDatasetList,
					this.dataArray.dotDataSetList,
					SHARE_REPORT_CONSTANTS.parentBarColorSet,
					SHARE_REPORT_CONSTANTS.childBarColorSet
				);
			}
		} else {
			this.disableloader();
		}
	}

	drawGraph(barDataset, dotDataSet, parentBarColors, childBarColors) {
		const svgContainerWidth = this.containerElement.nativeElement.offsetWidth;

		const svgContainerHeight = 580;
		const margin = { top: 30, right: 90, bottom: 100, left: 80 };
		const barWidth = 130,
			gapBetweenGroups = 10;
		const scaleYHeight = svgContainerHeight - margin.top - margin.bottom - 80;

		let scaleXParentWidthComputed = 0,
			x0ScaleWidthComputed = 0;
		this.isParentBarNeeded = false;

		(this.yMinLeft = 0), (this.yMaxLeft = 0), (this.yMinRight = 0), (this.yMaxRight = 0);
		this.getYminMaxLeftRight(barDataset, dotDataSet);
		let xParentScale, xParentAxis, xParentBarScale, xParentDotScale;
		/** Enable below If for implementing below point,
		 * x0ScaleWidthComputed - At any point of time, chart in screen display can show atmost 7 group bars.
		 * When it exceeds 7, Parent SVG and childProduct(x0ScaleWidthComputed)width is calculated where horizontal scroll will be enabled
		 **/
		if (barDataset.length > 7 && dotDataSet.length > 7) {
			this.isParentBarNeeded = true;
			scaleXParentWidthComputed = barWidth * 1 + gapBetweenGroups * 0.5;
			x0ScaleWidthComputed =
				barWidth * (barDataset.length - 1) + gapBetweenGroups * ((barDataset.length - 1) / 2);
			// Get Parent
			this.parentBarDataSet.push(barDataset[0]);
			this.parentDotDataSet.push(dotDataSet[0]);
			// Remove Parent from list
			barDataset.shift();
			dotDataSet.shift();
			this.barDataList = barDataset;
			xParentScale = d3
				.scaleBand()
				.range([0, scaleXParentWidthComputed])
				.paddingInner(0.2)
				.domain(
					this.parentBarDataSet.map(function (d) {
						return d.productName;
					})
				);
			xParentAxis = d3.axisBottom(xParentScale);
			xParentAxis.tickSizeOuter(0);

			xParentBarScale = d3
				.scaleBand()
				.domain(this.barChartKeys)
				.rangeRound([0, xParentScale.bandwidth()]);

			xParentDotScale = d3
				.scalePoint()
				.domain(this.dotChartKeys)
				.rangeRound([0, xParentScale.bandwidth()])
				.padding(0.6);
		} else {
			x0ScaleWidthComputed = svgContainerWidth - margin.left - margin.right - 5;
		}

		// X-axis
		let x0Scale = d3
			.scaleBand()
			.range([0, x0ScaleWidthComputed])
			.paddingInner(0.2)
			.domain(
				barDataset.map(function (d) {
					return d.productName;
				})
			);
		let xAxis = d3.axisBottom(x0Scale);
		xAxis.tickSizeOuter(0);

		let x1Scale = d3
			.scaleBand()
			.domain(this.barChartKeys)
			.rangeRound([0, x0Scale.bandwidth()])
			.paddingOuter(0.2);

		let x2Scale = d3
			.scalePoint()
			.domain(this.dotChartKeys)
			.rangeRound([0, x0Scale.bandwidth()])
			.padding(0.6);

		// Y-axis Left Scale
		let yLscale = d3.scaleLinear().range([scaleYHeight, 0]);
		yLscale.domain([this.yMinLeft, this.yMaxLeft]).nice();
		let yLaxis = d3
			.axisLeft(yLscale)
			.ticks(5)
			.tickFormat(function (d) {
				return d + '%';
			});

		// Y-axis Right Scale
		let yRScale = d3.scaleLinear().range([scaleYHeight, 0]);
		yRScale.domain([this.yMinRight, this.yMaxRight]).nice();
		let yRAxis = d3.axisRight(yRScale).ticks(8);
		let tooltip = d3.select('body').append('div').attr('class', 'line-chart-toolTip');

		const leftSVGWidth = svgContainerWidth - (svgContainerWidth - margin.left);
		const rightSVGWidth = svgContainerWidth - (svgContainerWidth - margin.right);
		const centerParentDivWidth = scaleXParentWidthComputed;
		const centerDivWidth = this.isParentBarNeeded
			? svgContainerWidth - leftSVGWidth - rightSVGWidth - scaleXParentWidthComputed - 5
			: svgContainerWidth - leftSVGWidth - rightSVGWidth;
		const svgHeight = scaleYHeight + margin.bottom + margin.top - 20;

		// Draw Left SVG
		if (this.dataArray.showBarChart) {
			let svgleftData = {
				svgHeight: svgHeight,
				leftSVGWidth: leftSVGWidth,
				margin: margin,
				yLaxis: yLaxis
			};
			this.drawSvgYLeft(svgleftData);
		}
		// Draw Fixed Parent center SVG with horizontal scrolling is enabled
		let parentbarSSvgData = {
			centerParentDivWidth: centerParentDivWidth,
			svgHeight: svgHeight,
			scaleXParentWidthComputed: scaleXParentWidthComputed,
			scaleYHeight: scaleYHeight,
			margin: margin,
			xParentScale: xParentScale,
			xParentBarScale: xParentBarScale,
			tooltip: tooltip,
			centerDivWidth: centerDivWidth,
			x0ScaleWidthComputed: x0ScaleWidthComputed,
			xAxis: xAxis,
			barDataset: barDataset,
			x0Scale: x0Scale,
			x1Scale: x1Scale,
			yLscale: yLscale,
			dotDataSet: dotDataSet,
			x2Scale: x2Scale,
			yRScale: yRScale,
			xParentAxis: xParentAxis,
			xParentDotScale: xParentDotScale,
			leftParentText: this.leftYAxisName,
			rightParentText: this.rightYAxisName,
			yMinLeft: this.yMinLeft
		};
		this.drawParentBarSVG(parentbarSSvgData);

		// Draw other graph center SVG - start
		this.drawSvgCenter(parentbarSSvgData);
		// Draw other graph center SVG - end

		// Draw Right SVG
		if (this.dataArray.showDotGraph) {
			let svgYRightData = {
				svgHeight: svgHeight,
				rightSVGWidth: rightSVGWidth,
				yRAxis: yRAxis,
				margin: margin
			};
			this.drawSvgYRight(svgYRightData);
		}
		// Draw Legends for Bar chart
		if (this.dataArray.showBarChart) {
			let legentData = {
				svgContainerWidth: svgContainerWidth,
				barDataset: barDataset,
				dotDataSet: dotDataSet,
				childBarColors: childBarColors,
				parentBarColors: parentBarColors
			};
			this.drawLegend(legentData);
		}
	}

	drawSvgYLeft(data) {
		let svgYLeft = d3
			.select('#wovtBarDotChartLeft')
			.append('svg')
			.attr('height', data.svgHeight)
			.attr('width', data.leftSVGWidth)
			.attr('id', 'wovtBarDotChartLeftSvg');

		// Draw Y-axis Left
		svgYLeft
			.append('g')
			.attr('id', 'yAxisLeft')
			.attr('class', 'wovtBarDotChart_yl_axis')
			.attr('transform', 'translate(' + (data.leftSVGWidth - 3) + ', ' + data.margin.top + ')')
			.call(data.yLaxis)
			.append('text')
			.attr('class', 'wovtBarDotChart_label')
			.attr('transform', 'rotate(-90),translate(' + -135 + ',' + -50 + ')')
			.style(this.TEXT_ANCHOR, 'end')
			.text(this.leftYAxisName);
	}

	drawParentBarSVG(data: any) {
		if (this.isParentBarNeeded) {
			const parentBarWidth = data.centerParentDivWidth + 'px';
			let svgParentCenter = d3
				.select('#wovtBarDotChartParentCenter')
				.style('width', function () {
					return parentBarWidth;
				})
				.style('max-width', function () {
					return parentBarWidth;
				})
				.style('display', this.INLINE_BLOCK)
				.append('svg')
				.attr('height', data.svgHeight)
				.attr('width', data.scaleXParentWidthComputed)
				.attr('id', 'wovtBarDotChartParentCenterSvg');

			// Draw Parent X-axis - Added 0.75 pixels to show X-line
			svgParentCenter
				.append('g')
				.attr('id', 'xParentAxis')
				.attr('class', 'wovtBarDotChart_x_axis')
				.attr('transform', 'translate(0,' + (data.scaleYHeight + data.margin.top + 0.75) + ')')
				.call(data.xParentAxis)
				.selectAll(this.TICK_TEXT_STR)
				.call(this.wrap, data.xParentScale.bandwidth());

			// Draw Parent Graph
			let parentBarGraph = svgParentCenter
				.selectAll('.parentBarProducts')
				.data(this.parentBarDataSet)
				.enter()
				.append('g')
				.attr('class', 'g')
				.attr('transform', function (d) {
					return 'translate(' + data.xParentScale(d.productName) + ', ' + data.margin.top + ')';
				});

			// Draw Parent rectangle groups - For transition Setting 'y' & 'height' to min and then to actual value
			if (this.dataArray.showBarChart) {
				let rectsParent = parentBarGraph
					.selectAll('rect')
					.data(function (d) {
						return d.values;
					})
					.enter()
					.append('rect')
					.attr('x', (d: any) => data.xParentBarScale(d.name))
					.attr('width', data.xParentBarScale.bandwidth())
					.attr('y', d => data.yLscale(data.yMinLeft))
					.attr('height', 0);

				rectsParent
					.transition()
					.duration(500)
					.delay(function (d, i) {
						return i * 8;
					})
					.attr('y', (d: any) => data.yLscale(d.value))
					.attr('height', (d: any) => data.scaleYHeight - data.yLscale(d.value))
					.attr('fill', (d: any) => d.color);

				rectsParent
					.on('mouseover', function (event, d: any) {
						d3.select(this)
							.transition()
							.duration(100)
							.style('stroke-width', 3)
							.style('stroke', d.color);
						data.tooltip
							.style('left', event.pageX - 48 + 'px')
							.style('top', event.pageY - 70 + 'px')
							.style('display', 'inline-block')
							.html(function () {
								const temp = d.value;
								return '<b>' + data.leftParentText + ':</b><span> ' + temp + '</span>';
							});
					})
					.on('mouseout', function (d) {
						d3.select(this).transition().duration(100).style('stroke', 'none');
						data.tooltip.style('display', 'none');
					});
			}

			// Dot Parent Chart SVG div
			if (this.dataArray.showDotGraph) {
				let dotParentGraph = svgParentCenter
					.selectAll('.dotParentProducts')
					.data(this.parentDotDataSet)
					.enter()
					.append('g')
					.attr('class', 'g')
					.attr('transform', function (d) {
						return 'translate(' + data.xParentScale(d.productName) + ',  ' + data.margin.top + ')';
					});

				// Draw Parent points graph in center SVG
				let pointsParent = dotParentGraph
					.selectAll('circle')
					.data(function (d: any) {
						return d.values;
					})
					.enter()
					.append('circle')
					.style('cursor', 'pointer')
					.attr('stroke', (d: any) => this.checkColor(d.value))
					.attr('fill', (d: any) => this.checkColor(d.value))
					.attr('cx', (d: any) => data.xParentDotScale(d.name))
					.attr('cy', (d: any) => data.yRScale(d.value))
					.attr('r', d => 5)
					.style('opacity', 0);

				pointsParent
					.transition()
					.duration(500)
					.delay(function (d, i) {
						return i * 8;
					})
					.style('opacity', 1);

				pointsParent
					.on('mouseover', function (event, d: any) {
						d3.select(this).transition().duration(100).attr('r', 7).attr('stroke-width', 2);
						data.tooltip
							.style('left', event.pageX - 48 + 'px')
							.style('top', event.pageY - 70 + 'px')
							.style('display', 'inline-block')
							.html(function () {
								const temp = d.value;
								return '<b>' + data.rightParentText + ':</b><span> ' + temp + '</span>';
							});
					})
					.on('mouseout', function (d, i) {
						d3.select(this).transition().duration(100).attr('r', 5).attr('stroke-width', 1);
						data.tooltip.style('display', 'none');
					});
			}
			// End of Parent Graph SVG
		} else {
			// Need to reset Parent SVG when no scrolling is needed and
			// only parent bar is shown using div id -> 'wovtBarDotChartOtherCenter'
			const scale = 0 + 'px';
			d3.select('#wovtBarDotChartParentCenter')
				.style('width', function () {
					return scale;
				})
				.style('max-width', function () {
					return scale;
				})
				.style('display', 'none');
		}
	}

	drawSvgCenter(svgCenterData) {
		const centerScrollDivHeight = svgCenterData.svgHeight + 'px';
		const centerScrollDivWidth = svgCenterData.centerDivWidth + 'px';
		d3.select('#wovtBarDotChartCenterScrollDiv')
			.attr('class', 'custom-chart-scroll')
			.style('height', function () {
				return centerScrollDivHeight;
			})
			.style(this.MAX_HEIGHT_STR, function () {
				return centerScrollDivHeight;
			})
			.style('width', function () {
				return centerScrollDivWidth;
			})
			.style('max-width', function () {
				return centerScrollDivWidth;
			})
			.style('display', this.INLINE_BLOCK)
			.style('overflow-x', 'auto')
			.style('overflow-y', 'hidden');

		// Set Height & Width for Center DIV for child elements
		const svgCenterHeight = this.isParentBarNeeded
			? svgCenterData.svgHeight - 70
			: svgCenterData.svgHeight;
		const otherCenterHeight = svgCenterHeight + 'px';
		const otherCenterWidth = svgCenterData.x0ScaleWidthComputed + 'px';
		let svgCenter = d3
			.select('#wovtBarDotChartOtherCenter')
			.style('height', function () {
				return otherCenterHeight;
			})
			.style(this.MAX_HEIGHT_STR, function () {
				return otherCenterHeight;
			})
			.style('width', function () {
				return otherCenterWidth;
			})
			.style('max-width', function () {
				return otherCenterWidth;
			})
			.append('svg')
			.attr('height', svgCenterHeight)
			.attr('width', svgCenterData.x0ScaleWidthComputed)
			.attr('id', 'wovtBarDotChartOtherCenterSvg');

		if (this.isParentBarNeeded) {
			// Draw X-axis - Added 0.75 pixels to show X-line
			this.showChildLabelText = true;
			svgCenter
				.append('g')
				.attr('id', 'xAxis')
				.attr('class', 'wovtBarDotChart_x_axis')
				.attr(
					'transform',
					'translate(0,' + (svgCenterData.scaleYHeight + svgCenterData.margin.top + 0.75) + ')'
				)
				.call(svgCenterData.xAxis)
				.selectAll(this.TICK_TEXT_STR)
				.style('display', 'none')
				.attr('y', d => -10);

			// Custom X-axis label display logic to increase chart load performance and reduce memory optimization
			const svgCenterChildLabelsHeight = svgCenterData.svgHeight - svgCenterHeight;
			const otherCenterLabelsHeight = svgCenterChildLabelsHeight + 'px';
			const otherCenterLabelsWidth = svgCenterData.x0ScaleWidthComputed + 'px';
			d3.select('#wovtBarDotChartOtherCenterLabels')
				.style('height', function () {
					return otherCenterLabelsHeight;
				})
				.style(this.MAX_HEIGHT_STR, function () {
					return otherCenterLabelsHeight;
				})
				.style('width', function () {
					return otherCenterLabelsWidth;
				})
				.style('max-width', function () {
					return otherCenterLabelsWidth;
				});

			this.labelWidth = svgCenterData.x0ScaleWidthComputed / svgCenterData.barDataset.length;
		} else {
			// Draw X-axis - Added 0.75 pixels to show X-line
			svgCenter
				.append('g')
				.attr('id', 'xAxis')
				.attr('class', 'wovtBarDotChart_x_axis')
				.attr(
					'transform',
					'translate(0,' + (svgCenterData.scaleYHeight + svgCenterData.margin.top + 0.75) + ')'
				)
				.call(svgCenterData.xAxis)
				.selectAll(this.TICK_TEXT_STR)
				.call(this.wrap, svgCenterData.x0Scale.bandwidth());
		}

		// Bar Chart G div
		let barGraph = svgCenter
			.selectAll('.barProducts')
			.data(svgCenterData.barDataset)
			.enter()
			.append('g')
			.attr('class', 'g')
			.attr('transform', function (d: any) {
				return (
					'translate(' +
					svgCenterData.x0Scale(d.productName) +
					', ' +
					svgCenterData.margin.top +
					')'
				);
			});

		// //Draw rectangle groups - For transition Setting 'y' & 'height' to min and then to actual value
		if (this.dataArray.showBarChart) {
			let rects = barGraph
				.selectAll('rect')
				.data(function (d: any) {
					return d.values;
				})
				.enter()
				.append('rect')
				.attr('x', (d: any) => svgCenterData.x1Scale(d.name))
				.attr('width', svgCenterData.x1Scale.bandwidth())
				.attr('y', d => svgCenterData.yLscale(svgCenterData.yMinLeft))
				.attr('height', 0);

			rects
				.transition()
				.duration(500)
				.delay(function (d, i) {
					return i * 8;
				})
				.attr('y', (d: any) => svgCenterData.yLscale(d.value))
				.attr('height', (d: any) => svgCenterData.scaleYHeight - svgCenterData.yLscale(d.value))
				.style('fill', (d: any) => d.color);

			rects
				.on('mouseover', function (event, d: any) {
					d3.select(this)
						.transition()
						.duration(100)
						.style('stroke', d.color)
						.style('stock-width', 3);
					svgCenterData.tooltip
						.style('left', event.pageX - 48 + 'px')
						.style('top', event.pageY - 70 + 'px')
						.style('display', 'inline-block')
						.html(function () {
							const temp = d.value;
							return '<b>' + svgCenterData.leftParentText + ':</b> <span> ' + temp + '</span>';
						});
				})
				.on('mouseout', function (d) {
					svgCenterData.tooltip.style('display', 'none');
					d3.select(this).transition().duration(100).style('stroke', 'none');
				});
		}

		// //Dot Chart G div
		if (this.dataArray.showDotGraph) {
			let dotGraph = svgCenter
				.selectAll('.dotProducts')
				.data(svgCenterData.dotDataSet)
				.enter()
				.append('g')
				.attr('class', 'g')
				.attr('transform', function (d: any) {
					return (
						'translate(' +
						svgCenterData.x0Scale(d.productName) +
						',  ' +
						svgCenterData.margin.top +
						')'
					);
				});

			// 	// Draw points graph in center SVG
			let points = dotGraph
				.selectAll('circle')
				.data(function (d: any) {
					return d.values;
				})
				.enter()
				.append('circle')
				.style('cursor', 'pointer')
				.attr('stroke', (d: any) => this.checkColor(d.value))
				.attr('fill', (d: any) => this.checkColor(d.value))
				.attr('cx', (d: any) => svgCenterData.x2Scale(d.name))
				.attr('cy', (d: any) => svgCenterData.yRScale(d.value))
				.attr('r', d => 5)
				.style('opacity', 0);

			points
				.transition()
				.duration(500)
				.delay(function (d, i) {
					return i * 8;
				})
				.style('opacity', 1);

			points
				.on('mouseover', function (event, d: any) {
					d3.select(this).transition().duration(100).attr('stroke-width', 2).attr('r', 7);
					svgCenterData.tooltip
						.style('left', event.pageX - 48 + 'px')
						.style('top', event.pageY - 70 + 'px')
						.style('display', 'inline-block')
						.html(function () {
							const temp = d.value;
							return '<b>' + svgCenterData.rightParentText + ':</b> <span> ' + temp + '</span>';
						});
				})
				.on('mouseout', function (d, i) {
					d3.select(this).transition().duration(100).attr('stroke-width', 1).attr('r', 5);
					svgCenterData.tooltip.style('display', 'none');
				});
		}
	}

	drawSvgYRight(svgYRightData) {
		let svgYRight = d3
			.select('#wovtBarDotChartRight')
			.append('svg')
			.attr('height', svgYRightData.svgHeight)
			.attr('width', svgYRightData.rightSVGWidth)
			.attr('id', 'wovtBarDotChartRightSvg');
		//Draw Y-axis Right
		svgYRight
			.append('g')
			.attr('id', 'yAxisRight')
			.attr('class', 'wovtBarDotChart_yr_axis')
			.attr('transform', 'translate(0 ,' + svgYRightData.margin.top + ')')
			.call(svgYRightData.yRAxis)
			.append('text')
			.attr('class', 'wovtBarDotChart_label')
			.attr('transform', 'rotate(-90),translate(' + -70 + ',' + 60 + ')')
			.style(this.TEXT_ANCHOR, 'end')
			.text(this.rightYAxisName);

		svgYRight.selectAll(this.TICK_TEXT_STR).style('fill', d => this.checkColor(d));
	}

	drawLegend(legendData) {
		let legendSvg = d3
			.select('#wovtBarDotChartLegend')
			.attr('width', legendData.svgContainerWidth)
			.attr('height', 50)
			.append('svg')
			.attr('id', 'wovtBarDotChartLegendSvg')
			.attr('width', legendData.svgContainerWidth)
			.attr('height', 50);

		const screenCenterPosition = legendData.svgContainerWidth / 2 - 200;
		let legendGroup = legendSvg
			.append('g')
			.attr('id', 'legendGroup')
			.attr('transform', 'translate(' + screenCenterPosition + ', 0)')
			.style('align-items', 'center');

		const colorSet =
			legendData.barDataset.length > 1 && legendData.dotDataSet.length
				? legendData.childBarColors
				: legendData.parentBarColors;
		let selectedPeriodCount = 0;
		let legentToShow = this.filteredLegendsToShow;
		this.selectedPeriod.forEach((d, i) => {
			const label = 'Latest ' + d.gkey + ' Weeks';
			const color =
				d.key === '4weeks'
					? colorSet[0]
					: d.key === '13weeks'
					? colorSet[1]
					: d.key === '52weeks'
					? colorSet[2]
					: '';
			const rx = selectedPeriodCount * 150,
				ry = 0;
			this.createLegend(label, color, d.key, rx, ry, legendGroup);
			selectedPeriodCount++;
		});
	}

	createLegend(legendText, legendColor, id, xPos, yPos, legendGroup) {
		let group = legendGroup
			.append('g')
			.attr('id', id)
			.attr('transform', 'translate(' + xPos + ',' + yPos + ')')
			.style('cursor', 'pointer')
			.style('opacity', '1');
		let updateChart = () => {
			this.resetChartData();
			this.generateWovtBarDotChartData();
			this.updateLegendsOpacity();
		};
		let filteredLegendsToShow = this.filteredLegendsToShow;
		group.on('click', function (event, d: any) {
			const groupId = id;
			filteredLegendsToShow.forEach(function (e) {
				if (e.key === groupId) {
					e.isSelected = !e.isSelected;
				}
			});
			updateChart();
		});

		group
			.append('rect')
			.attr('width', '14')
			.attr('height', '14')
			.attr('stroke', legendColor)
			.attr('fill', legendColor)
			.attr('stroke_width', 1)
			.style('opacity', 0)
			.transition()
			.duration(600)
			.style('opacity', 1);

		group
			.append('text')
			.attr('class', 'wovtBarDotChart_legend')
			.attr(this.TEXT_ANCHOR, 'left')
			.attr('transform', 'translate(20, 12)')
			.attr('value', legendText)
			.text(legendText);
	}

	wrap(label, width) {
		label.each(function () {
			const text = d3.select(this);
			const dy = parseFloat(text.attr('dy'));
			const y = text.attr('y');
			const lineHeight = 1.1;
			const words = text.text().split(/\s+/).reverse();
			const dyVal = dy + 'em';
			let word,
				line = [],
				lineNumber = 0,
				tspan = text.text(null).append('tspan').attr('x', 0).attr('y', y).attr('dy', dyVal);
			while ((word = words.pop())) {
				line.push(word);
				tspan.text(line.join(' '));
				if (tspan.node().getComputedTextLength() > width) {
					++lineNumber;
					const dyValue = lineNumber * lineHeight + dy + 'em';
					line.pop();
					tspan.text(line.join(' '));
					line = [word];
					tspan = text.append('tspan').attr('x', 0).attr('y', y).attr('dy', dyValue).text(word);
				}
			}
		});
	}

	updateLegendsOpacity() {
		setTimeout(() => {
			this.filteredLegendsToShow.forEach((period: any) => {
				if (period.isSelected) {
					document.getElementById(period.key).style.opacity = '1';
				} else {
					document.getElementById(period.key).style.opacity = '0.3';
				}
			});
		});
	}

	checkColor(value) {
		value = parseFloat(value);
		if (value < 0) {
			return '#DD0014';
		} else if (value > 0) {
			return '#26B910';
		} else {
			return '#000000';
		}
	}

	getYminMaxLeftRight(barValues: any, dotValues: any) {
		const yValuesLeft = [],
			yValuesRight = [];
		_.forEach(barValues, function (dataItem) {
			_.forEach(dataItem.values, function (item) {
				yValuesLeft.push(parseFloat(item.value));
			});
		});
		this.yMaxLeft = d3.max(yValuesLeft);
		if (d3.min(yValuesLeft) === 0 || (d3.min(yValuesLeft) > 0 && d3.min(yValuesLeft) < 30)) {
			this.yMinLeft = 0;
		} else if (d3.min(yValuesLeft) >= 30 && d3.min(yValuesLeft) < 50) {
			this.yMinLeft = 20;
		} else if (d3.min(yValuesLeft) >= 50) {
			this.yMinLeft = 30;
		}
		_.forEach(dotValues, function (dataItem) {
			_.forEach(dataItem.values, function (item) {
				yValuesRight.push(parseFloat(item.value));
			});
		});
		this.yMinRight = d3.min(yValuesRight);
		this.yMinRight = this.yMinRight - 100;
		this.yMaxRight = d3.max(yValuesRight);
		this.yMaxRight = this.yMaxRight + 100;
	}

	/* Load chart data */
	loadChartData() {
		let filterMesaures = this.measures.filter(item => item.value === this.selectedUnits);
		console.log(filterMesaures);
		this.measureSelected = filterMesaures[0];
		this.factSelected = this.factLocalPromptValues[this.measureSelected.key][0];
		if (
			this.selectedFactGraph &&
			(this.selectedFactGraph.key === 'wmDollarSharePTChgBpsTwoYA' ||
				this.selectedFactGraph.key === 'wmUnitsSharePTChgBpsTwoYA' ||
				this.selectedFactGraph.key === 'wmEqUnitSharePTChgBpsTwoYA')
		) {
			this.factSelected = this.factLocalPromptValues[this.measureSelected.key][1];
		}
		this.period_tab_latest_sort = {
			name: 'WM $ Share',
			direction: 'desc'
		};
		this.selectedMeasure.emit(this.measureSelected);
		this.factSelectedData.emit(this.factSelected);
	}

	updateMeasureSelection(measure) {
		if (measure && this.measureSelected === measure) {
			return;
		}
		this.period_tab_latest_sort = {
			name:
				measure.value === '$'
					? 'WM $ Share'
					: measure.value === 'EQ'
					? 'WM EQ Units Share'
					: 'WM Units Share',
			direction: 'desc'
		};
		this.sortDetails.emit(this.period_tab_latest_sort);
		this.selectedMeasure.emit(measure);
		this.enableLoader = true;
		setTimeout(() => {
			this.measureSelected = measure;
			if (
				this.selectedFactGraph &&
				(this.selectedFactGraph.key === 'wmDollarSharePTChgBpsTwoYA' ||
					this.selectedFactGraph.key === 'wmUnitsSharePTChgBpsTwoYA' ||
					this.selectedFactGraph.key === 'wmEqUnitSharePTChgBpsTwoYA')
			) {
				this.factSelected = this.factLocalPromptValues[this.measureSelected.key][1];
				this.updateFactSelection(this.factLocalPromptValues[this.measureSelected.key][1]);
			} else {
				this.factSelected = this.factLocalPromptValues[this.measureSelected.key][0];
				this.updateFactSelection(this.factLocalPromptValues[this.measureSelected.key][0]);
			}
			this.resetChartData();
			this.generateWovtBarDotChartData();
			this.enableLoader = false;
		}, 3000);

		this.showUnitDropdown = false;
	}

	updateFactSelection(fact) {
		if (fact && this.factSelected === fact) {
			return;
		}
		this.factSelectedData.emit(fact);
		this.enableLoader = true;
		setTimeout(() => {
			// <<<---using ()=> syntax
			this.factSelected = fact;
			this.resetChartData();
			this.generateWovtBarDotChartData();
			this.showFactDropdown = false;
			this.enableLoader = false;
		}, 3000);
	}
}
