const DOMAIN_NAME = 'nielseniq.com';
const DOMAIN_NAME_IO = 'nielseniq.io';
export const CONTEXT_ROOT = '/jbprestapi';

export const EXPORT_URL = 'https://wmovtasposerestserviceuat.nielseniq.io/exportrestapi/sbs/export';
export const OVT_UI_URL = {
	DEV: 'https://jbpdev.nielseniq.io/ovt',
	LOCAL: 'http://127.0.0.1:8080/ovt',
	//DEV: 'http://walmartovtservices-23-9-2-0-qa-vmss-us-eastus2.azure-wmovt-np.nielsencsp.net',
	//DEV: 'https://jbprestserviceuat.nielseniq.io',
	UAT: 'https://oneversiontruthuat.nielseniq.io/ovt',
	PROD: 'https://oneversiontruth.nielseniq.com'
};

export const OIDC_HEADER_URL = {
	LOCAL: 'https://uat.dc1.answers.nielseniq.io/oidc?info=json',
	DEV: 'https://uat.dc1.answers.nielseniq.io/oidc?info=json',
	UAT: 'https://uat.dc2.answers.nielseniq.io/oidc?info=json',
	PROD: 'https://dc2.answers.nielseniq.com/oidc?info=json'
};

export const REDIRECTING_ANSWERS_PORTAL_URL = {
	LOCAL: 'https://uat.dc1.answers.nielseniq.io/ovt',
	DEV: 'https://uat.dc1.answers.nielseniq.io/ovt',
	UAT: 'https://uat.dc2.answers.nielseniq.io/ovt',
	PROD: 'https://dc2.answers.nielseniq.com/ovt'
};

export const BASE_URL = {
	//LOCAL: 'http://walmartovtservices-23-9-2-0-qa-vmss-us-eastus2.azure-wmovt-np.nielsencsp.net/jbprestapi',
	LOCAL: 'https://jbprestservicedev.' + DOMAIN_NAME_IO + '/jbprestapi',
	DEV: 'https://jbprestservicedev.' + DOMAIN_NAME_IO + '/jbprestapi',
	UAT: 'https://jbprestserviceuat.' + DOMAIN_NAME_IO + '/jbprestapi',
	PROD: 'https://oneversiontruthrestservice.' + DOMAIN_NAME + '/jbprestapi'
};

export const REDIRECTING_ANSWERS_PORTAL_URL_WHEN_LOGOUT = {
	LOCAL:
		'https://uat.gateway.nielseniq.io/logout?TARGET=https%3A%2F%2Fuat.dc1.answers.nielseniq.io%2Fovt',
	DEV: 'https://uat.gateway.nielseniq.io/logout?TARGET=https%3A%2F%2Fuat.dc1.answers.nielseniq.io%2Fovt',
	UAT: 'https://uat.gateway.nielseniq.io/logout?TARGET=https%3A%2F%2Fuat.dc2.answers.nielseniq.io%2Fovt',
	PROD: 'https://gateway.nielseniq.com/logout?TARGET=https%3A%2F%2Fdc2.answers.nielseniq.com%2Fovt'
};

export const APP_INSIGHTS_KEY = {
	LOCAL: 'd8a36d71-437d-4eed-a09c-ca94566bbc04',
	DEV: 'd8a36d71-437d-4eed-a09c-ca94566bbc04',
	UAT: 'd6e17784-89dc-40ab-a9da-5cb124c9f47f',
	PROD: 'a68ba818-99b8-41e0-9fc0-d39efdc432c9'
};

export const REDIRECTING_TO_ERRORPAGE = {
	DEV: '/jbp/error.html',
	UAT: '/jbp/error.html',
	PROD: '/ovt/error.html'
};

export const OVT_UI_URL_HOME_PAGE_URLS = {
	QUESTIONS: '/jbp/common/v1/questions',
	APPS: '/jbp/common/v1/apps',
	NOTIFICATIONS: '/jbp/notifications/getNotifications',
	FAVORITES_ADD: '/jbp/favorites/add/question/',
	COMMENT_SERVICE: '/jbp/common/v1/clientfeedback/save',
	OAUTH_DETAILS: '/unoauth/v1/userinfo',
	GET_FAVORITES: '/jbp/favorites/getfavorites',
	FAVORITES_REMOVE: '/jbp/favorites/delete/question/',
	VIEWED_NOTIFICATION_MESSAGES: '/jbp/notifications/viwed/message/'
};

export const OVT_UI_URL_DASHBOARD_SELECTION = {
	GET_SAVED_SELECTION: '/jbp/common/v1/getselectors/',
	SAVE_USER_SELECTION: '/jbp/common/v1/saveselectors/',
	FIND_FRESH_PRODUCTS: '/jbp/common/v1/findFreshProducts',
	DASHBOARD_SAVE_USER_SELECTION: '/jbp/common/v1/saveDefaultselectors/',
	GET_ALL_RMS_MARKET_NAMES: '/jbp/common/v1/market/name/rms/all',
	GET_ALL_PANEL_MARKET_NAMES: '/jbp/common/v1/market/name/panel/all',
	GET_ALL_RMS_PERIODS: '/jbp/common/v1/periods/info/rms',
	GET_ALL_PANEL_PERIODS: '/jbp/common/v1/periods/info/panel',
	GET_ALL_BEATS_PERIODS: '/jbp/common/v1/periods/info/beats',
	PERIODS_END_DATE: '/jbp/common/v1/getperiodenddate/'
};

export const OVT_UI_URL_AZ_SEARCH = {
	INDIVIDUAL_SEARCH: '/jbp/selector/v1/kpidashboard/query/individualsearch/',
	GROUP_SEARCH: '/jbp/selector/v1/kpidashboard/query/groupsearch/',
	MULTI_SEARCH: '/jbp/selector/v1/kpidashboard/query/multisearch/',
	SUGGESTIONS: '/jbp/selector/v1/selectors/query/suggestions/',
	GET_PARENT_COMPANY: '/jbp/selector/v1/kpidashboard/getparentcompany',
	GET_CHILD_LEVELS: '/jbp/common/v1/getchildlevels/'
};

export const PRODUCT_PERFORMANCE_DASHBOARD = {
	LHS: '/jbp/productperformance/v1/report/LHS/',
	RHS: '/jbp/productperformance/v1/report/RHS/'
};

export const PANEL_DASHBOARD = {
	GET_INFO: '/jbp/contributiondrivers/v1/report'
};

export const SALES_DRIVER_DASHBOARD_ENDPOINT = {
	TABLE_DATA: '/jbp/rankingreport/v1/report/'
};
let query = true;
export const CONCIERGE_URL = {
	GET_CLIENTS: '/jbp/concierge/v1/getclientsv2',
	GET_ORGANIZATIONS: '/jbp/selector/v1/kpidashboard/query/individualsearch/5/BRDOWNHGH',
	GET_ENHANCEMENT_SUMMARY: '/jbp/concierge/v1/getEnhancementSummary',
	GET_TRAINING_MATERIAL: '/jbp/common/v1/trainingmaterial',
	SAVE_TRAINING_MATERIAL: '/jbp/common/v1/trainingmaterialSave',
	UPDATE_TRAINING_MATERIAL_SEQUENCE: '/jbp/common/v1/trainingMaterialUpdateSequence',
	CREATE_TRAINING_MATERIAL: '/jbp/common/v1/trainingMaterialCreate',
	DELETE_TRAINING_MATERIAL: '/jbp/common/v1/trainingMaterialDelete',
	CONCIERGE_GET_GROUPS: '/jbp/concierge/v1/getgroups',
	CONCIERGE_GET_CATEGORIES: '/jbp/concierge/v1/getdimcategories',
	CONCIERGE_GET_APPS: '/jbp/concierge/v1/getapps',
	CONCIERGE_GET_DASHBOARDS: '/jbp/concierge/v1/getdashboards',
	CONCIERGE_GET_ORGANIZATIONS:
		'/jbp/selector/v1/kpidashboard/' + (query ? 'query/' : '') + 'individualsearch/5/BRDOWNHGH',
	GET_MANUFACTURER: '/jbp/concierge/v1/getexistingvendors',
	CONCIERGE_GET_GROUP_BY_ID: '/jbp/concierge/v1/getgroup/',
	CONCIERGE_EDIT_GROUP: '/jbp/concierge/v1/groups/',
	CONCIERGE_GET_USERS_BY_EMAIL: '/jbp/concierge/v1/getusers',
	CONCIERGE_UPDATE_USER_BY_EMAIL: '/jbp/concierge/v1/getusers',
	CONCIERGE_GET_USERSGROUPS_BY_EMAIL: '/jbp/concierge/v1/getusergroups',
	GET_DEPARTMENTS: '/jbp/concierge/v1/getdepartments',
	SAVE_PUSH_NOTIFICATION_MESSAGE: '/jbp/concierge/v1/savemessage',
	SAVE_GROUP: '/jbp/concierge/v1/savegroup',
	SAVE_USER: '/jbp/concierge/v1/saveuser',
	CREATE_ENHANCEMENT_SUMMARY: '/jbp/concierge/v1/createEnhancementSummary',
	UPDATE_ENHANCEMENT_SUMMARY: '/jbp/concierge/v1/updateEnhancementSummary',
	SAS_TRAINING_MATERIAL: '/jbp/common/v1/trainingmaterial/generatesas',
	UPDATE_ENHANCEMENT_SUMMARY_ACTIVE: '/jbp/concierge/v1/updateEnhancementSummaryActive',
	DELETE_ENHANCEMENT_SUMMARY: '/jbp/concierge/v1/deleteEnhancementSummary',
	UPDATE_ENHANCEMENT_SUMMARY_USERVIEWED: '/jbp/concierge/v1/updateEnhancementSummaryUserViewed',
	CONCIERGE_ADD_EXISTING_USER_TO_GROUP: '/jbp/concierge/v1/addusergroup/',
	CONCIERGE_DELETE_USER_BY_EMAIL: '/jbp/concierge/v1/deleteuser',
	CONCIERGE_GET_SUGGESTED_CATEGORIES_BY_MANUFACTURER: '/jbp/concierge/v1/getcategories',
	CONCIERGE_GET_POWERBI_REPORTS: '/jbp/concierge/v1/getpowerbireports',
	CONCIERGE_GET_BANNER: '/jbp/common/v1/getEnhancementSummaryDetailUserLevel',
	CONCIERGE_USAGE_STATS_TOKEN: '/jbp/concierge/v1/azureembedtoken'
};

export const LEAKAGE_DASHBOARD_ENDPOINT = {
	TREE_NODES_URL: '/jbp/dashboard/leakageTree/treeNodes',
	PURCHASE_URL: '/jbp/dashboard/leakageTree/purchaseMetrics',
	TREE_URL: '/jbp/dashboard/leakageTree/table'
};
export const ITEM_ASSORTMENT_DASHBOARD = {
	REPORT: '/jbp/assortment/v1/report',
	TAB_DATA: '/jbp/assortment/v1/bylevel/report'
};

export const PRODUCT_COMPARISON_ENDPOINTS = {
	TABLE_URL: '/jbp/dashboard/kpi/metrics/tablepost',
	MARKET_URL: '/jbp/dashboard/kpi/metrics/marketspost'
};
export const GEOGRAPHIC_DEEP_DIVE_ENDPOINTS = {
	TABLE_POST_URL: '/jbp/dashboard/geodeepdive/tablepost',
	MARKET_POST_URL: '/jbp/dashboard/geodeepdive/marketspost'
};
export const PRICE_BEATS_DASHBOARD_URL = {
	TABLE_POST: '/jbp/dashboard/geobeats/tablepost',
	MARKET_POST: '/jbp/dashboard/geobeats/marketspost'
};
