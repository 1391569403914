import { Injectable } from '@angular/core';
import {
	HttpEvent,
	HttpHandler,
	HttpHeaders,
	HttpInterceptor,
	HttpRequest
} from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class NlsnDataRequestInterceptor implements HttpInterceptor {
	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		const newRequest = req.clone({
			headers: new HttpHeaders().set('X-Requested-With', 'XMLHttpRequest')
		});
		return next.handle(newRequest);
	}
}
