import { Component, OnInit, OnDestroy } from '@angular/core';
import { PRODUCT_PERFORMANCE } from 'src/app/constants/dashboard-ui.constant';
import { ChartRequestData, ReportDimension } from 'src/app/models/report-dimension.model';
import { pageHoverTexts, SELECTOR_TYPES } from 'src/app/models/report-hovertexts.constant';
import { ProductPerformanceService } from '../services/product-performance.service';
import { UtilityService } from '../services/utility.service';
import { forkJoin, map, Subject, takeUntil } from 'rxjs';
import { TextChangePipe } from 'src/app/pipes/text-change.pipe';
import { ToastService } from '@nielseniq/athena-core';
import { TOAST } from 'src/app/constants/toast.constant';
import * as d3 from 'd3';
import { TopbarUtilityService } from 'src/app/core/service/topbar/topbar-utility.service';
import {
	PDT_RM_DOLLAR_COLUMNS,
	PDT_RM_EQ_COLUMNS,
	PDT_RM_UNITS_COLUMNS,
	PDT_WM_DOLLAR_COLUMNS,
	PDT_WM_EQ_COLUMNS,
	PDT_WM_UNITS_COLUMNS
} from 'src/app/models/product-performance.constant';

@Component({
	selector: 'crf-ca-product-performance',
	templateUrl: './product-performance.component.html',
	styleUrl: './product-performance.component.scss'
})
export class ProductPerformanceComponent implements OnInit, OnDestroy {
	showDropdown = {
		product: false,
		byLevel: false,
		market: false,
		fact: false,
		period: false
	};
	qid: number = 2;
	selectedProductToShow: { level: string; value: string } = {
		level: '',
		value: ''
	};
	productPerformanceCtrl: ReportDimension = {
		dashboardName: 'Product Performance (Scan)',
		selectedMarket: [],
		period: '',
		multiMarketEnabled: true
	};
	requestPayload: ChartRequestData = {
		brandOwnerHigh: '',
		brandOwnerLow: '',
		category: '',
		categoryGroup: '',
		cyPeriod: '',
		department: '',
		fact: '',
		level: '',
		majorBusiness: '',
		market: [],
		period: 0,
		rmMarket: [],
		segment: '',
		subCategory: '',
		totalBusiness: '',
		wmMarket: [],
		yaPeriod: ''
	};
	totalUs = {
		key: 'Total US',
		value: {
			remainingMarket: 'Walmart Total US xAOC Rem',
			walmartMarket: 'Walmart Total US TA'
		}
	};
	pageHoverTexts: any = pageHoverTexts;
	selectorTypes = SELECTOR_TYPES;
	categoryList: any;
	periodList: any = [];
	marketList: any;
	marketMenuItems: any = [];
	factList: { key: string; value: string; displayName: string }[];
	periodData: any = {};
	pp_values: any;
	categoryText: string;
	selectedUnit: string;
	chartInfoReady: boolean;
	isOpen: boolean;
	isDataNOTAvailable: boolean;
	defaultProductError: boolean;
	isProductPerformanceInfoReady: boolean = false;
	isCurrentContributionMetric: boolean = false;
	isExportDownloadInProcess: boolean = false;
	contribution_metrics_hovertext: string = '';
	RHS_LIST: any;
	listHeight: number = 54;
	CHART_MAX_VALUE: number;
	CHART_MIN_VALUE: number;
	selectedTab: string = 'top10_div';
	selectedSort: any = { label: 'Actual Chg', value: 'actual' };
	selectedItem: any = {
		productName: 'DIAPERS'
	};
	sortOptions = [
		{ label: 'Actual Chg', value: 'actual' },
		{ label: 'Sales', value: 'sales' }
	];
	objFact: {
		actual: { top10: any; bottom10: any; remainingtop10: any; remainingbottom10: any };
		sales: { top10: any; bottom10: any; remainingtop10: any; remainingbottom10: any };
	};

	PP_CON = PRODUCT_PERFORMANCE;
	RMDollarDefs = PDT_RM_DOLLAR_COLUMNS;
	RMUnitsDefs = PDT_RM_UNITS_COLUMNS;
	RMEQDefs = PDT_RM_EQ_COLUMNS;
	WMDollarDefs = PDT_WM_DOLLAR_COLUMNS;
	WMUnitsDefs = PDT_WM_UNITS_COLUMNS;
	WMEQDefs = PDT_WM_EQ_COLUMNS;

	protected readonly unSubscribeAll: Subject<void> = new Subject<void>();
	pageTitle: any;

	constructor(
		private productPerformanceService: ProductPerformanceService,
		private utilityService: UtilityService,
		private toastService: ToastService,
		private textChangePipe: TextChangePipe,
		private topBarUtility: TopbarUtilityService
	) {}

	ngOnInit() {
		this.pageTitle = this.topBarUtility.getPageTitleReport();
		this.onInitialLoad();
	}

	onInitialLoad() {
		const requestArray = [
			this.utilityService.getDefaultSelectors(this.qid),
			this.productPerformanceService.getAllMarket(),
			this.productPerformanceService.getAllRMSPeriod(),
			this.productPerformanceService.retriveFactData()
		];
		forkJoin(requestArray)
			.pipe(
				map(([defaultAll, allMarket, allPeriod, allFact]) => {
					return {
						defaultAll: defaultAll,
						allMarket: allMarket,
						allPeriod: allPeriod,
						allFact: allFact
					};
				})
			)
			.pipe(takeUntil(this.unSubscribeAll))
			.subscribe({
				next: response => {
					if (response['allMarket']) {
						this.marketList = response['allMarket'];
						Object.entries(this.marketList).map(market => {
							let marketEntry = { key: market[0], value: market[1], isSelected: false };
							this.marketMenuItems.push(marketEntry);
						});
					}

					if (response['allPeriod']) {
						this.periodData.periods = response['allPeriod'];
						Object.keys(this.periodData.periods).forEach(item => {
							if (this.periodData.periods[item].defaultPeriod) {
								this.periodData.default_key = item;
								this.periodData.currentPeriod = this.periodData.periods[item].currentPeriod;
								this.periodData.previousPeriod = this.periodData.periods[item].previousPeriod;
								this.periodData.twoYAPeriod = this.periodData.periods[item].twoYAPeriod;
								this.periodData.id = this.periodData.periods[item].id;
							}
							this.periodList.push({ key: this.periodData.periods[item].id, value: item });
						});
					}

					if (response['allFact']) {
						this.factList = response['allFact'];
					}

					if (response['defaultAll']) {
						this.productPerformanceService.defaultSelectors = response['defaultAll'];
						this.onApplyDefault(response['defaultAll'], 7);
					}
					this.loadDashboard();
					this.getPeriodEndDate(this.periodData?.id, response?.['defaultAll']);
				},
				error: error => {
					console.log('Error occured', error);
				}
			});
	}

	getPeriodEndDate(period, productSelections) {
		let data = {
			department: productSelections?.department,
			categoryGroup: productSelections?.categorygroup,
			category: productSelections?.category,
			subCategory: productSelections?.subcategory,
			segment: productSelections?.segment,
			brandOwnerHigh: productSelections?.brandownerhigh,
			brandOwnerLow: productSelections?.brandownerlow,
			brandHigh: productSelections?.brandhigh,
			totalBusiness: productSelections?.totalbusiness,
			majorBusiness: productSelections?.majorbusiness
		};
		this.productPerformanceService
			.getPeriodEndDate(this.qid, period, data)
			.pipe(takeUntil(this.unSubscribeAll))
			.subscribe(value => (this.productPerformanceCtrl.periodEndDate = value.periodEndDate));
	}

	onApplyDefault(response, selector) {
		if (selector === 3 || selector === 7) {
			let value: any = this.productPerformanceService.getSelectedProducts(response);
			this.productPerformanceService
				.retriveLevelData(value.selectedProduct.level, this.qid, value)
				.pipe(takeUntil(this.unSubscribeAll))
				.subscribe(val => {
					this.categoryList = val.result;
					let defaultIndex = this.productPerformanceService.findDefaultByLevel(
						val.result,
						value.selectedProduct.level,
						'key'
					);
					this.productPerformanceCtrl.selectedLevelToShow = this.categoryList[defaultIndex].value;
					this.utilityService.preselectDefault(
						this.categoryList,
						this.productPerformanceCtrl.selectedLevelToShow
					);
					this.requestPayload.level = this.categoryList[defaultIndex].key;
					this.loadDashboard();
				});
			this.selectedProductToShow = value.selectedProduct;
			this.productPerformanceCtrl.selectedProduct = [];
			if (response?.totalbusiness) {
				this.productPerformanceCtrl.selectedProduct.push({
					displayName: 'Total Business',
					displayValue: [response.totalbusiness]
				});
				this.requestPayload.totalBusiness = response.totalbusiness;
			}
			if (response?.majorbusiness) {
				this.productPerformanceCtrl.selectedProduct.push({
					displayName: 'Major Business',
					displayValue: [response.majorbusiness]
				});
				this.requestPayload.majorBusiness = response.majorbusiness;
			}
			if (response?.department) {
				this.productPerformanceCtrl.selectedProduct.push({
					displayName: 'Department',
					displayValue: [response.department]
				});
				this.requestPayload.department = response.department;
			}
			if (response?.categorygroup) {
				this.productPerformanceCtrl.selectedProduct.push({
					displayName: 'Category Group',
					displayValue: [response.categorygroup]
				});
				this.requestPayload.categoryGroup = response.categorygroup;
			}
			if (response?.category) {
				this.productPerformanceCtrl.selectedProduct.push({
					displayName: 'Category',
					displayValue: [response.category]
				});
				this.requestPayload.category = response.category;
			}
			if (response?.subcategory) {
				this.productPerformanceCtrl.selectedProduct.push({
					displayName: 'Subcategory',
					displayValue: [response.subcategory]
				});
				this.requestPayload.subCategory = response.subcategory;
			}
			if (response?.segment) {
				this.productPerformanceCtrl.selectedProduct.push({
					displayName: 'Segment',
					displayValue: [response.segment]
				});
				this.requestPayload.segment = response.segment;
			}
			if (response?.brandownerhigh) {
				this.productPerformanceCtrl.selectedProduct.push({
					displayName: 'Brand Owner High',
					displayValue: [response.brandownerhigh]
				});
				this.requestPayload.brandOwnerHigh = response.brandownerhigh;
			}
			if (response?.brandownerlow) {
				this.productPerformanceCtrl.selectedProduct.push({
					displayName: 'Brand Owner Low',
					displayValue: [response.brandownerlow]
				});
			}
			this.requestPayload.brandOwnerLow = response.brandownerlow;
		}

		if (selector === 5 || selector === 7) {
			this.productPerformanceCtrl.period = response.periodIds
				? response.periodIds
				: this.periodData.default_key;
			this.utilityService.preselectDefault(this.periodList, this.productPerformanceCtrl.period);
			this.requestPayload.period = this.periodData.periods[this.productPerformanceCtrl.period].id;
			this.requestPayload.cyPeriod =
				this.periodData.periods[this.productPerformanceCtrl.period].currentPeriod;
			this.requestPayload.yaPeriod =
				this.periodData.periods[this.productPerformanceCtrl.period].previousPeriod;
		}

		if (selector === 2 || selector === 7) {
			this.marketMenuItems = [];
			if (response.market === 'Total US' || response.market === null) {
				Object.entries(this.marketList).map(market => {
					let marketEntry = { key: market[0], value: market[1], isSelected: false };
					this.marketMenuItems.push(marketEntry);
				});
				this.productPerformanceCtrl.selectedMarket = ['Total US'];
				this.requestPayload.rmMarket = [this.totalUs.value.remainingMarket];
				this.requestPayload.wmMarket = [this.totalUs.value.walmartMarket];
			} else {
				Object.entries(this.marketList).map(market => {
					let marketEntry = {
						key: market[0],
						value: market[1],
						isSelected: this.utilityService
							.defaultSelectorSplit(response.market)
							.includes(market[0])
					};
					this.marketMenuItems.push(marketEntry);
				});
				this.productPerformanceCtrl.selectedMarket = this.utilityService.defaultSelectorSplit(
					response.market
				);
				this.productPerformanceCtrl.selectedMarket.forEach(market => {
					this.requestPayload.wmMarket.push(this.marketList[market].walmartMarket);
					this.requestPayload.rmMarket.push(this.marketList[market].remainingMarket);
				});
			}
			this.requestPayload.market = this.productPerformanceCtrl?.selectedMarket;
		}

		if (selector === 4 || selector === 7) {
			this.productPerformanceCtrl.volumeBasis = response.factType ?? this.factList[0].value;
			this.requestPayload.fact = this.factList.filter(
				fact => fact.value === this.productPerformanceCtrl.volumeBasis
			)[0].key;

			this.utilityService.preselectDefault(this.factList, this.productPerformanceCtrl.volumeBasis);
		}
	}

	onMoreOptionClicked(data) {
		if (data.menuId === 1) {
			let selectorType = this.selectorTypes[data.selectorId].type;
			let selectorValues = this.saveSelectionMetaData();
			let payload = this.getSaveDefaultPayload(data.menuId, data.selectorId, selectorValues);
			let queryParam = this.qid + '?type=' + selectorType;
			if (selectorType !== 'all') {
				this.productPerformanceService.defaultSelectors[selectorType] =
					selectorValues[selectorType];
			} else {
				this.productPerformanceService.defaultSelectors = {
					brandownerhigh: this.requestPayload.brandOwnerHigh,
					brandownerlow: this.requestPayload.brandOwnerLow,
					category: this.requestPayload.category,
					categorygroup: this.requestPayload.categoryGroup,
					department: this.requestPayload.department,
					majorbusiness: this.requestPayload.majorBusiness,
					totalbusiness: this.requestPayload.totalBusiness,
					factType: selectorValues.factType,
					market: selectorValues.market,
					periodIds: selectorValues.periodIds
				};
			}
			this.utilityService
				.saveItemAsDefault(payload, queryParam)
				.pipe(takeUntil(this.unSubscribeAll))
				.subscribe({
					next: res => {
						this.toastService.InjectToast(
							'success',
							'Selections saved successfully',
							'',
							TOAST.TIMEOUT,
							'',
							'Success',
							TOAST.SIZE
						);
					},
					error: err => console.log(err)
				});
		} else if (data.menuId === 2) {
			if (data.selectorId === 7 || data.selectorId === 3) {
				this.requestPayload.brandOwnerHigh = '';
				this.requestPayload.brandOwnerLow = '';
				this.requestPayload.category = '';
				this.requestPayload.categoryGroup = '';
				this.requestPayload.department = '';
				this.requestPayload.majorBusiness = '';
				this.requestPayload.segment = '';
				this.requestPayload.subCategory = '';
				this.requestPayload.totalBusiness = '';
				this.utilityService
					.getDefaultSelectors(this.qid)
					.pipe(takeUntil(this.unSubscribeAll))
					.subscribe(response => {
						this.productPerformanceService.defaultSelectors = response;
						this.onApplyDefault(response, 7);
						this.loadDashboard();
					});
			} else {
				this.onApplyDefault(this.productPerformanceService.defaultSelectors, data.selectorId);
				this.loadDashboard();
			}
		}
	}

	getSaveDefaultPayload(type, selectorId, params) {
		if (type === 1) {
			let paramsObj = {};
			if (selectorId === 0 || selectorId === 7) {
				paramsObj = {
					brand: '',
					brandFamily: '',
					brandHigh: '',
					brandLow: '',
					brandOwnerHigh: this.requestPayload.brandOwnerHigh,
					brandOwnerLow: this.requestPayload.brandOwnerLow,
					byLevel: this.productPerformanceCtrl.selectedLevelToShow,
					category: this.requestPayload.category,
					categoryGroup: this.requestPayload.categoryGroup,
					department: this.requestPayload.department,
					majorBusiness: this.requestPayload.majorBusiness,
					segment: this.requestPayload.segment,
					subCategory: this.requestPayload.subCategory,
					totalBusiness: this.requestPayload.totalBusiness
				};
			}
			for (let i in this.selectorTypes) {
				if ((selectorId == Number(i) || selectorId === 7) && i !== '0') {
					if (params[this.selectorTypes[i].type] !== undefined) {
						paramsObj[this.selectorTypes[i].type] = params[this.selectorTypes[i].type];
					}
				}
			}
			return paramsObj;
		}
	}

	saveSelectionMetaData() {
		return {
			market: this.productPerformanceCtrl.selectedMarket.join(';'),
			periodIds: this.productPerformanceCtrl.period,
			factType: this.productPerformanceCtrl.volumeBasis
		};
	}

	toggleDropdown(dropdown: string) {
		this.showDropdown[dropdown] = !this.showDropdown[dropdown];
	}

	closeDropdown(dropdown: string) {
		this.showDropdown[dropdown] = false;
	}

	updateProductSelection(selectedProduct) {
		let value: any;
		let selProduct = {};
		this.defaultProductError = false;
		selectedProduct?.optedValues.forEach(level => {
			let key = level.payloadField.toLowerCase();
			if (key !== 'brandfamily') {
				if (selectedProduct.saveDefault)
					this.productPerformanceService.defaultSelectors[key] = level.value;
				selProduct[key] = level.value;
				this.requestPayload[level.payloadField] = level.value;
			}
		});
		value = this.productPerformanceService.getSelectedProducts(selProduct);
		this.productPerformanceService
			.retriveLevelData(value.selectedProduct.level, this.qid, value)
			.pipe(takeUntil(this.unSubscribeAll))
			.subscribe(val => {
				this.categoryList = val.result;
				let defaultIndex = this.productPerformanceService.findDefaultByLevel(
					val.result,
					value.selectedProduct.level,
					'key'
				);
				this.productPerformanceCtrl.selectedLevelToShow = this.categoryList[defaultIndex].value;
				this.utilityService.preselectDefault(
					this.categoryList,
					this.productPerformanceCtrl.selectedLevelToShow
				);
				this.requestPayload.level = this.categoryList[defaultIndex].key;
				this.loadDashboard();
			});
		this.selectedProductToShow = {
			level: selectedProduct?.selectedProduct?.key,
			value: selectedProduct?.selectedProduct?.value
		};
		this.productPerformanceCtrl.selectedProduct = [];
		selectedProduct?.optedValues
			.filter(item => item.value !== '' && item.value !== null)
			.map(item => {
				this.productPerformanceCtrl.selectedProduct.push({
					displayName: this.textChangePipe.transform(item.alias_name),
					displayValue: [item.value]
				});
			});
	}

	levelSelected(data: any) {
		this.productPerformanceCtrl.selectedLevelToShow = data.value;
		this.requestPayload.level = data.key;
		this.loadDashboard();
		this.showDropdown.byLevel = false;
	}

	addselectedMarket(selectedMarket: any) {
		this.productPerformanceCtrl.selectedMarket = [];
		this.requestPayload.wmMarket = [];
		this.requestPayload.rmMarket = [];
		selectedMarket.forEach(item => {
			this.productPerformanceCtrl.selectedMarket.push(item.key);
		});
		this.requestPayload.market = [...this.productPerformanceCtrl.selectedMarket];
		this.productPerformanceCtrl.selectedMarket.forEach(market => {
			this.requestPayload.wmMarket.push(this.marketList[market].walmartMarket);
			this.requestPayload.rmMarket.push(this.marketList[market].remainingMarket);
		});
		this.loadDashboard();
		this.showDropdown.market = false;
	}

	factSelected(data: any) {
		this.productPerformanceCtrl.volumeBasis = data.value;
		this.requestPayload.fact = data.key;
		this.loadDashboard();
		this.showDropdown.fact = false;
	}

	periodSelected(data: any) {
		this.productPerformanceCtrl.period = data.value;
		this.requestPayload.period = data.key;
		this.requestPayload.cyPeriod =
			this.periodData.periods[this.productPerformanceCtrl.period].currentPeriod;
		this.requestPayload.yaPeriod =
			this.periodData.periods[this.productPerformanceCtrl.period].previousPeriod;
		this.loadDashboard();
		this.showDropdown.period = false;
	}

	loadDashboard() {
		this.isProductPerformanceInfoReady = false;
		this.selectedUnit = this.productPerformanceCtrl.volumeBasis;
		if (
			!this.productPerformanceCtrl.selectedLevelToShow ||
			!this.productPerformanceCtrl.selectedMarket ||
			!this.productPerformanceCtrl.volumeBasis ||
			!this.productPerformanceCtrl.period
		) {
			return;
		}

		this.chartInfoReady = false;
		this.getProductPerformanceChartInfo();
		let defaultObj = {
			hierarchy: 'current',
			productName: this.selectedProductToShow.value
		};
		this.getProductPerformanceInfo(defaultObj);
	}

	getProductPerformanceChartInfo() {
		this.chartInfoReady = false;
		let reqObj = { ...this.requestPayload };
		this.productPerformanceService
			.getChartData(reqObj)
			.pipe(takeUntil(this.unSubscribeAll))
			.subscribe(data => {
				if (this.utilityService.isValid(data)) {
					this.RHS_LIST = data.resp;
					this.generateGroupChart(data);
					this.chartInfoReady = true;
				}
			});
	}

	getProductPerformanceInfo(data) {
		this.isCurrentContributionMetric = data.hierarchy === 'current';
		this.isProductPerformanceInfoReady = false;
		this.selectedItem = data;
		let rhsReqObj = {
			...this.requestPayload,
			hierarchy: data.hierarchy,
			selectedValue: data.productName ? data.productName : ''
		};
		this.selectedSort = { label: 'Actual Chg', value: 'actual' };
		this.productPerformanceService
			.getProductPerformanceInfo(rhsReqObj)
			.pipe(takeUntil(this.unSubscribeAll))
			.subscribe({
				next: (response: any) => {
					this.categoryText = data.productName;
					this.pp_values = response.volumetrics;
					this.objFact = {
						actual: {
							top10: response.top10,
							bottom10: response.bottom10,
							remainingtop10: response.remainingtop10,
							remainingbottom10: response.remainingbottom10
						},
						sales: {
							top10: response.top10sales,
							bottom10: response.bottom10sales,
							remainingtop10: response.remainingtop10sales,
							remainingbottom10: response.remainingbottom10sales
						}
					};
					this.isProductPerformanceInfoReady = true;
				},
				error: (error: any) => {
					this.isProductPerformanceInfoReady = false;
					this.isDataNOTAvailable = true;
				}
			});
	}

	generateGroupChart(data) {
		let chartDataSet = data.chart;
		let chartBounds = data.chartBounds;
		this.isDataNOTAvailable = chartBounds.Max === 0.0 && chartBounds.Min === 0.0;

		if (this.isDataNOTAvailable) {
			this.defaultProductError = true;
			this.toastService.InjectToast(
				'warning',
				'',
				'',
				5000,
				'Any two levels of product selection is mandatory',
				'Warning',
				TOAST.SIZE
			);
		}

		d3.select('#barGroupChart').remove();

		let zippedData = chartDataSet.values;
		let labels = chartDataSet.labels;

		const containerId = 'maGroupChart';
		const SVG_ID = 'barGroupChart';

		const seriesOneColor = '#007dc6'; // old color "#006699"; // Walmart color
		const seriesTwoColor = '#f47321'; // old color "#44AAAA"; //Remaining Market color
		//let MAX_VALUE = 100;
		const MAX_VALUE = Math.ceil(chartBounds.Max);
		const MIN_VALUE = Math.floor(chartBounds.Min);

		//For PPT Report
		this.CHART_MAX_VALUE = MAX_VALUE;
		this.CHART_MIN_VALUE = MIN_VALUE;

		//let MIN_VALUE = -200;
		// Max Domain value
		const BARS_IN_GROUP = 2;
		const gapBetweenGroups = 10;

		// const divWidth = this.maGroupChart.nativeElement.offsetWidth;
		const divWidth = document.getElementById('maGroupChart').offsetWidth;
		const barHeight = 22;
		let divHeight = barHeight * zippedData.length + gapBetweenGroups * (zippedData.length / 2);

		let spaceForLabels = 0.3125 * divWidth;

		let margin = {
			top: 0.02 * divHeight,
			right: 0.1 * divWidth,
			bottom: 0.02 * divHeight,
			left: 0.0625 * divWidth
		};

		let chartWidth = divWidth - margin.left - margin.right;

		// Added Color Codes for series
		const color = d3.scaleLinear().domain([0, 1]).range([seriesOneColor, seriesTwoColor]);
		// X-axis scale
		const x = d3.scaleLinear().domain([0, 1]).rangeRound([0, chartWidth]);

		x.domain([MIN_VALUE, MAX_VALUE]).nice(); // Setting X Domain

		// Y-axis scale
		const y = d3
			.scaleBand()
			.domain(labels.map((d, key) => d + ',' + key))
			.padding(0.1);

		y.rangeRound([divHeight, 0]).padding(0.4);

		// Select the Tag to which svg need to append
		const selectedDiv = d3.select(`#${containerId}`);
		// Appending svg element and Padding left to make the chart center
		const svg = selectedDiv
			.append('svg')
			.attr('id', SVG_ID)
			.attr('width', divWidth)
			.attr('height', divHeight + 60);

		// Specify the chart area and dimensions svgContainer
		const g = svg.append('g');
		const tooltip = d3.select('body').append('div').attr('class', 'dashboard-toolTip');

		// Adding Top Row
		let genMaxValue = 0;
		let genMinValue = 0;
		const xAxis = g.append('g').attr('class', 'x axis').attr('transform', 'translate(20,0)');

		xAxis.call(
			d3.axisTop(x).tickFormat(d => {
				if (genMaxValue < d) {
					genMaxValue = d;
				}
				if (genMinValue > d) {
					genMinValue = d;
				}
				return d;
			})
		);

		//For PPT Report
		this.CHART_MAX_VALUE = genMaxValue;
		this.CHART_MIN_VALUE = genMinValue;
		// Adding X axis
		g.append('g')
			.attr('class', 'x axis')
			.attr('transform', 'translate(' + margin.left + ', ' + divHeight + ')')
			.call(
				d3
					.axisBottom(x)
					.tickSizeInner(-divHeight)
					.tickSizeOuter(0)
					.tickFormat(d => {
						if (d === genMaxValue || d === genMinValue || d === 0) {
							return d + '%';
						}
						return d;
					})
			);

		// Selecting and styling tick lines
		g.selectAll('.tick line').style('opacity', d => {
			return d === 0 ? 1 : null;
		});

		// Create bars
		const bar = g
			.selectAll('.bar')
			.data(zippedData)
			.enter()
			.append('g')
			.attr('transform', (d, i) =>
				this.getBarsPostions(
					i,
					barHeight,
					gapBetweenGroups,
					BARS_IN_GROUP,
					spaceForLabels,
					margin,
					d
				)
			)
			.on('click', (d, event) => this.getProductPerformanceInfo(event));

		// Adding Y axis
		g.append('g')
			.attr('class', 'y axis')
			.attr('transform', 'translate(' + margin.left + ', ' + 0 + ')')
			.call(
				d3
					.axisLeft(y)
					.tickSizeInner(-chartWidth)
					.tickSizeOuter(0)
					.tickFormat((d, i, param) => {
						const extraSpace = gapBetweenGroups * (0.5 + Math.floor((i + i) / BARS_IN_GROUP));
						const yPoint = (i + i) * barHeight + extraSpace + barHeight;

						if (i === 0 && yPoint > 0) {
							this.listHeight = yPoint * 2;
						}

						param[i].parentNode.setAttribute('transform', 'translate(' + 0 + ', ' + yPoint + ')');
						return '';
					})
					.tickPadding(0.2)
			)
			.selectAll('.tick text')
			.attr('x', d => -5);

		// Create rectangles of the correct width
		bar
			.append('rect')
			.style('fill', (d, i) => color(i % BARS_IN_GROUP))
			.attr('class', 'bar')
			.attr('x', d => {
				let checkVal = parseFloat(d.walmartSalesChange || d.remainingMarketSalesChange);
				if (checkVal < MIN_VALUE && checkVal < 0) {
					return 0;
				}
				return x(Math.min(0, checkVal));
			})
			.attr('y', d => y(parseFloat(d.walmartSalesChange || d.remainingMarketSalesChange)))
			.attr('width', d => {
				let checkVal = parseFloat(d.walmartSalesChange || d.remainingMarketSalesChange);
				if (checkVal > MAX_VALUE || (checkVal < MIN_VALUE && checkVal < 0)) {
					return Math.abs(x(0));
				}
				return Math.abs(x(checkVal) - x(0));
			})
			.attr('height', barHeight)
			.on('mouseover', (event, d) => {
				let checkVal = d.walmartSalesChange || d.remainingMarketSalesChange;
				let toolTipMarkets;
				let str =
					this.productPerformanceCtrl.selectedMarket &&
					this.productPerformanceCtrl.selectedMarket.length > 1
						? 'Markets'
						: 'Market';
				if (this.utilityService.isValid(d.walmartSalesChange)) {
					toolTipMarkets = 'Walmart ' + str;
				} else {
					toolTipMarkets = 'Remaining ' + str;
				}
				let color1 = 'font-green-value';
				let factType =
					this.selectedUnit === '$'
						? '$ Sales % Chg &nbsp&nbsp&nbsp'
						: this.selectedUnit + ' % Chg &nbsp&nbsp&nbsp';
				if (checkVal <= 0) {
					color1 = 'font-red-value';
				}
				tooltip
					.style('display', 'inline-block')
					.style('left', event.pageX - 50 + 'px')
					.style('top', event.pageY - 100 + 'px')
					.html(
						d.productName +
							"<span class='arrow'></span>" +
							'<br>' +
							toolTipMarkets +
							'<br>' +
							factType +
							'<span class=' +
							color1 +
							'>' +
							checkVal +
							'%</span>'
					);
			})
			.on('mouseout', d => {
				tooltip.style('display', 'none');
			});

		let polygonLeftOffset = -110; // left offset of white polygon = 0px of barGroupChart
		let rightMarginFromChart = 5;

		// polygonFullOffset = offset from left to right
		let polygonFullOffset = polygonLeftOffset + chartWidth - rightMarginFromChart;

		bar
			.append('polygon')
			.attr('points', '100,5 110,5 105,27 95,27')
			.attr('class', (d, i) => {
				let checkVal = parseFloat(d.walmartSalesChange || d.remainingMarketSalesChange);
				let check = 'circls';
				if (checkVal <= MAX_VALUE && checkVal >= 0) {
					check += ' circls-hide';
				}
				if (checkVal <= 0 && checkVal >= MIN_VALUE) {
					check += ' circls-hide';
				}
				return check;
			})
			.attr('transform', (d, i) => {
				let checkVal = parseFloat(d.walmartSalesChange || d.remainingMarketSalesChange);
				let coordinateX = polygonFullOffset;
				let coordinateY = -5;
				if (checkVal < 0) {
					coordinateX = -90;
					coordinateY = -5;
				}
				return 'translate(' + coordinateX + ',' + coordinateY + ')';
			});
	}

	getBarsPostions(
		iteratorValue,
		barHeight,
		gapBetweenGroups,
		BARS_IN_GROUP,
		spaceForLabels,
		margin,
		center
	) {
		const barPostion = iteratorValue * barHeight;
		const extraSpace = gapBetweenGroups * (0.5 + Math.floor(iteratorValue / BARS_IN_GROUP));

		const coordinateX = margin.left;
		const coordinateY = extraSpace + barPostion;

		return 'translate(' + coordinateX + ',' + coordinateY + ')';
	}

	setLabelListHeight() {
		if (this.listHeight <= 0) {
			return {
				height: '54'
			};
		} else {
			return {
				height: this.listHeight
			};
		}
	}

	checkColor(value) {
		if (!value) {
			return;
		}

		value = parseFloat(value.replace(/[%$,]/g, ''));

		if (value < 0) {
			return {
				color: '#DD0014'
			};
		} else if (value > 0) {
			return {
				color: '#26B910'
			};
		}

		return {
			color: '#000000'
		};
	}

	isActive(item, level) {
		let tempText = '';
		let tempFlag = false;

		if (level === 'level1') {
			if (this.utilityService.isValid(item.current)) {
				if (item.current.productName === this.selectedItem?.productName) {
					tempFlag = true;
				} else {
					tempText = this.isCurrentContributionMetric ? item.current.productName : 'saas';
					tempFlag = false;
				}

				if (this.selectedItem?.hierarchy === 'current' && tempText !== '') {
					this.contribution_metrics_hovertext =
						'$ Contribution of ' + this.categoryText + ' to ' + tempText;
				} else if (this.selectedItem?.hierarchy === 'child') {
					this.contribution_metrics_hovertext =
						'$ Contribution of ' + this.categoryText + ' to ' + this.selectedProductToShow.value;
				} else {
					this.contribution_metrics_hovertext = '';
				}
				return tempFlag;
			}
		} else if (level === 'level2') {
			return item.productName === this.selectedItem?.productName;
		}
		return false;
	}

	reportDownload(downloadType: 'excel' | 'ppt') {
		let reportData = {
			applicationType: 'JBP',
			dataModelType: 'PRODUCTPERFORMANCE',
			level: this.requestPayload.level,
			bylevel: this.productPerformanceCtrl.selectedLevelToShow,
			market: this.productPerformanceCtrl.selectedMarket,
			period: this.requestPayload.period,
			factType: this.requestPayload.fact,
			hierarchyType: this.selectedItem?.hierarchy,
			hierarchy: this.selectedItem?.hierarchy,
			selectedValue: this.selectedItem.productName,
			department: this.requestPayload.department,
			categoryGroup: this.requestPayload.categoryGroup,
			category: this.requestPayload.category,
			subCategory: this.requestPayload.subCategory || '',
			segment: this.requestPayload.segment || '',
			brandOwnerHigh: this.requestPayload.brandOwnerHigh || '',
			brandOwnerLow: this.requestPayload.brandOwnerLow || '',
			scope: this.selectedProductToShow.level,
			parentScope: '',
			periodEndDate: this.productPerformanceCtrl.periodEndDate,
			totalBusiness: this.requestPayload.totalBusiness || '',
			majorBusiness: this.requestPayload.majorBusiness || '',
			chartMax: this.CHART_MAX_VALUE,
			chartMin: this.CHART_MIN_VALUE,
			cyPeriod: this.requestPayload.cyPeriod,
			yaPeriod: this.requestPayload.yaPeriod,
			wmMarket: this.requestPayload.wmMarket,
			rmMarket: this.requestPayload.rmMarket
		};
		this.utilityService.exportFile(reportData, downloadType);
	}

	sortDataBy(index: number) {
		this.selectedSort = this.sortOptions[index];
	}

	selectTab(tab: string) {
		this.selectedTab = tab;
	}

	ngOnDestroy(): void {
		this.unSubscribeAll.next();
		this.unSubscribeAll.complete();
	}
}
