import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { TopbarService } from 'src/app/core/service/topbar/topbar.service';
import { AppMenuItem } from '../../model/AppMenuItem.model';
import { TrainingService } from 'src/app/concierge/services/training.service';
import { ReplaySubject, takeUntil } from 'rxjs';
import * as _ from 'lodash';
import { AppInsightsService } from 'src/app/service/app-insights.service';

@Component({
	selector: 'crf-ca-app-section',
	templateUrl: './app-section.component.html',
	styleUrl: './app-section.component.scss'
})
export class AppSectionComponent implements OnInit {
	apiMenuItems: AppMenuItem[] = [];
	displayMenuItems: AppMenuItem[] = [];

	@Output() appMenuSelect: EventEmitter<void> = new EventEmitter<void>();
	@Input() hover: boolean = true;
	@Input() isConcierge: boolean = false;

	unsubscribeOnDestroy$: ReplaySubject<any> = new ReplaySubject<any>();

	constructor(
		private topbarService: TopbarService,
		private router: Router,
		private trainingMaterialService: TrainingService,
		private appInsightsService: AppInsightsService
	) {}

	ngOnInit(): void {
		this.load();
	}

	load(): void {
		this.topbarService
			.getAppMenuItems()
			.pipe(takeUntil(this.unsubscribeOnDestroy$))
			.subscribe(response => {
				this.apiMenuItems = response.result;
				this.updateMenu(this.router.url.toLowerCase().includes('concierge'));
			});

		//Changing url: conceirge or home based on page
		this.router.events.subscribe({
			next: event => {
				if (event instanceof NavigationEnd) {
					console.log(event.url);
					if (event.url.includes('concierge')) this.updateMenu(true);
					else this.updateMenu(false);
				}
			}
		});
	}

	updateMenu(isConcierge: boolean) {
		this.displayMenuItems = this.apiMenuItems.filter(menuItem =>
			isConcierge ? !menuItem?.text.toLowerCase().includes('concierge') : true
		);

		if (isConcierge)
			this.displayMenuItems.push({
				id: 4,
				text: 'Walmart One Version of Truth',
				image: 'assets/img/app-ovt.png',
				altText: 'Walmart One Version of Truth',
				navLink: '/',
				hoverText: 'Walmart One Version of Truth',
				jbpApp: 'true'
			} as AppMenuItem);
	}

	navigateToAppItem(app: AppMenuItem) {
		this.appMenuSelect.emit();

		if (_.isFunction(this.appInsightsService.trackPage)) {
			this.appInsightsService.trackPage('navigated to : ' + app.text);
		}

		let exclusion = ['Concierge Module', 'Walmart One Version of Truth'];

		if (
			app.jbpApp === 'true' &&
			exclusion.map(e => e.toLowerCase()).indexOf(app.text.toLowerCase()) === -1
		) {
			this.trainingMaterialService.getTrainingMaterial().subscribe({
				next: data => {
					const topic_JSON = data.Documents;
					let documentWithLink = null;

					topic_JSON.forEach(function (topic) {
						const document = topic.resource.find(function (e) {
							return (
								e.heading === app.navLink ||
								e.heading === app.navLink + '_Supplier' ||
								e.heading === app.navLink + '_Walmart'
							);
						});

						if (document) documentWithLink = document;
					});

					if (documentWithLink && documentWithLink.blobUrl)
						window.open(documentWithLink.blobUrl, '_blank');
				}
			});
		} else if (app.jbpApp === 'true') {
			if (app.navLink === 'concierge.home') this.router.navigateByUrl('/concierge/conciergeHome');
			else if (app.navLink === '/') this.router.navigateByUrl('/');
		} else {
			window.open(app.navLink, '_blank');
		}
	}

	ngOnDestroy() {
		this.unsubscribeOnDestroy$.next(true);
		this.unsubscribeOnDestroy$.complete();
	}
}
